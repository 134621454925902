import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ApiService} from './api.service';
import {PermissionService} from './permission.service';
import {LanguageService} from './language.service';

@Injectable({
    providedIn: 'root'
})
export class LayoutService {
    toggled = new BehaviorSubject(false);
    hideHeader$ = new BehaviorSubject(false);
    hideSidebar$ = new BehaviorSubject(false);
    NavigationEnd$ = new BehaviorSubject<NavigationEnd>({
        id: 0,
        url: '',
        urlAfterRedirects: ''
    });

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private api: ApiService,
        private permissionService: PermissionService,
        private languageService: LanguageService
    ) {
        if (sessionStorage.getItem('hideHeader')) {
            this.hideHeader$.next(true);
        }
        if (sessionStorage.getItem('hideSidebar')) {
            this.hideSidebar$.next(true);
        }

        this.router.events.subscribe(evt => {
            if (evt instanceof NavigationEnd) {
                setTimeout(() => {
                    this.toggled.next(false);
                }, 100);
            }
        });

        this.route.queryParams.subscribe(async q => {
            if (+q.ProgramId == 4) {
                // console.log('call api  userinfo');
            }
            if (+q.hideFrame === 1) {
                this.hideHeader$.next(true);
                this.hideSidebar$.next(true);
            }

            if (+q.hideFrame === 0) {
                this.hideHeader$.next(false);
                this.hideSidebar$.next(false);
            }

            if (+q.hideSidebar === 1) {
                this.hideSidebar$.next(true);
            }

            if (+q.hideSidebar === 0) {
                this.hideSidebar$.next(false);
            }
        });

        this.hideHeader$.subscribe(x => {
            if (x) {
                sessionStorage.setItem('hideHeader', 'hide');
            } else {
                sessionStorage.removeItem('hideHeader');
            }
        });

        this.hideSidebar$.subscribe(x => {
            if (x) {
                sessionStorage.setItem('hideSidebar', 'hide');
            } else {
                sessionStorage.removeItem('hideSidebar');
            }
        });

        this.route.queryParams.subscribe(async q => {
            let _EmployeeId = this.api.getEmployeeId();

            if (+q.EmployeeId > 0) {
                this.api.setEmployeeId(q.EmployeeId);
            }
            if ((q.ProgramId || q.ProgramId == null) && Object.keys(q).includes('ProgramId')) {
                this.api.setProgramId(q.ProgramId);
            }
            if (q.token) {
                this.api.setToken(q.token);
            }
            if (q.lang) {
                this.languageService.use(q.lang);
            }

            // if (+q.ProgramId == 5) {
            //     await this.permissionService.getUserInfoBookACar(q.EmployeeId, q.ProgramId);
            // }

            let EmployeeId = this.api.getEmployeeId();
            let ProgramId = this.api.getProgramId();
            if (EmployeeId || ProgramId) {
                await this.api
                    .EDGetUserInfo()
                    .toPromise()
                    .then((res: any) => {
                        if (res.successful) {
                            let data = res.data[0];
                            this.api.setUserInfo(data);
                            this.api.setSubDetailMenu(res.SubDetail);
                            this.api.setMenuSarabun(res.Menu);
                            if (_EmployeeId != EmployeeId) {
                                window.location.reload();
                            }
                        }
                    });
            }

            if (ProgramId == '4') {
                await this.permissionService.getUserInfoPersonnelSystem();
            }

            if (ProgramId == '5') {
                await this.permissionService.getUserInfoBookACar();
            }
        });
    }
}
