import {APP_INITIALIZER, Injector, Provider} from '@angular/core';
import {first} from 'rxjs/operators';
import {ServiceLocator} from '../../services/service-locator.service';
// import { AppService } from "src/app/akita/app/app.service";

// function _setUpEncryptionKey(appFetchService: AppFetchService, appStoreService: AppService): () => Promise<any> {
//     return async () => {
//         let key: string | undefined
//         try {
//             key = appStoreService.getFields('publicKey')
//         } catch (error) {}

//         return key || await appFetchService.getPublicKey().pipe(
//             first(),
//         ).toPromise().catch(err => // console.log('[pK:Exc]' , err))
//     }
// }

function _setupServiceLocator(injector: Injector): () => Promise<any> {
    return async () => {
        ServiceLocator.mainInjector = injector;
        return;
    };
}

const SetupServiceLocator: Provider = {
    provide: APP_INITIALIZER,
    useFactory: _setupServiceLocator,
    deps: [Injector],
    multi: true
};

// const SetupEncryptionKey : Provider = {
//     provide : APP_INITIALIZER,
//    // useFactory: _setUpEncryptionKey,
//     //deps: [AppFetchService, AppService],
//     multi: true
// }

export const AppInitializerProviders: Provider[] = [
    SetupServiceLocator
    // SetupEncryptionKey,
];
