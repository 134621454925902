export const environment = {
    production: false,
    version: require('../../package.json').version,
    debug: false,
    build: window['globalsconfig'].build,
    DEFAULT_LANGUAGE: 'th',
    Dynamsoft: {
        resourcesPath: 'assets/dwt-resources',
        dwtProductKey: window['globalsconfig'].dwtProductKey,
        uploadTargetURL: ''
    },
    API_SERVER_ADDRESS: window['globalsconfig'].API_SERVER_ADDRESS,
    API_GETIP: window['globalsconfig'].API_GETIP,
    Signal_R: window['globalsconfig'].Signal_R,
    MAX_UPLOAD_FILE_SIZE: window['globalsconfig'].MAX_UPLOAD_FILE_SIZE, //MB
    ALLOW_EXTENSION_FILE: window['globalsconfig'].ALLOW_EXTENSION_FILE,
    Api_oae_Meeting: window['globalsconfig'].Api_oae_Meeting,
    uapi: window['globalsconfig'].uapi
};
