import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    CountNotification$ = new BehaviorSubject(0);

    constructor(public api: ApiService) {
        return;
        // eslint-disable-next-line angular/interval-service
        setInterval(x => {
            this.getNotification();
        }, 15000);
    }

    getNotification() {
        this.api.GetNotification(this.api.user.StaffId).subscribe(res => {
            if (res.successful) {
                var CountNotification = res.data[0].CountNotification;
                this.CountNotification$.next(CountNotification);
            }
        });
    }
}
