import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../shared/services/api.service';
import { AlertService } from '../../../shared/services/alert.service';
@Component({
    selector: 'app-review',
    templateUrl: './review.component.html',
    styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements OnInit {
  Data = new FormGroup({
    DriverService: new FormControl(''),
    SatisfactionService: new FormControl(''),
    Feedback: new FormControl(''),
  });
  TData = new FormGroup({})
  date: any;
  today = new Date();
  pageShow = true;
  FormCarId = '';
  ReviewerId = '';
  fdata = [];
  IsRepeat = false;
  RepeatMessage = '';
  cid = ''
  constructor(private Api: ApiService,
    private route: ActivatedRoute,
    public alertService: AlertService) { 
    
    }

  async ngOnInit() {
    const queryParams = this.route.snapshot.queryParams;
    this.FormCarId = queryParams.FormCarId;
    this.ReviewerId = queryParams.rid;
    this.cid = queryParams.cid;

    
    this.TData.addControl('FormCarId', new FormControl(queryParams.FormCarId, Validators.required));
    this.TData.addControl('ReviewerId', new FormControl(queryParams.rid, Validators.required));
    this.TData.addControl('cid', new FormControl(queryParams.cid, Validators.required));
    this.fdata = [{
      title: 'แบบประเมินความพึงพอใจ',
      detail: '',
      data: [{
        title: '1.การให้บริการของพนักงานขับรถ',
        type: 'radio',
        name: 'DriverService',
        required: true,
        value: [{
          text: 'มากที่สุด',
          value: 5
        }, {
          text: 'มาก',
            value: 4
          }, {
          text: 'ปานกลาง',
            value: 3
          }, {
          text: 'พอใช้',
            value: 2
          }, {
          text: 'ควรปรับปรุง',
            value: 1
          },]
      },
        {
          title: '2.ความพึงพอใจต่อการให้บริการโดยรวม',
          type: 'radio',
          name: 'SatisfactionService',
          required: true,
          value: [{
            text: 'มากที่สุด',
            value: 5
          }, {
            text: 'มาก',
            value: 4
          }, {
            text: 'ปานกลาง',
            value: 3
          }, {
            text: 'พอใช้',
            value: 2
          }, {
            text: 'ควรปรับปรุง',
            value: 1
          },]
        },
        {
          title: '3.ความคิดเห็น/ข้อเสนอแนะ (ถ้ามี)',
          type: 'textarea',
          name: 'Feedback',
          required: false,
          value: ''
        },]
    }];
    this.fdata.map(f => {
      f.data.map(s => {
        if (s.required){
          this.TData.addControl(s.name, new FormControl('', Validators.required));
        } else {
          this.TData.addControl(s.name, new FormControl(''));
        }
        
      })
    })
    /*this.TData.addControl('DriverService', new FormControl(''));
    this.TData.addControl('SatisfactionService', new FormControl(''));
    this.TData.addControl('Feedback', new FormControl(''));*/


    // check repeat
    let res = await this.Api.BCCheckReview(this.TData.value).toPromise();
    if (!res.successful) {
      this.IsRepeat = !res.successful;
      this.RepeatMessage = res.Message;
      return
    }
  }

  async save() {
    if (this.TData.valid) {
      // console.log(this.TData.valid);
      this.Api.BCInserReview(this.TData.value).subscribe(res => {
        if(res.successful) {
          this.pageShow = false;
          var date = new Date().getUTCDate();
          var month = (new Date().getUTCMonth()) + 1
          var year = (new Date().getUTCFullYear()) + 543
          this.date = date + '/' + month + '/' + year
        }
      })
    } else {
      this.alertService.Error('กรุณากรอกข้อมูล')
    }
    /*var data = {
      FormCarId: this.FormCarId,
      ReviewerId: this.ReviewerId,
      DriverService: this.Data.value.DriverService,
      SatisfactionService: this.Data.value.SatisfactionService,
      Feedback: this.Data.value.Feedback
    }
    if (!data.DriverService || !data.SatisfactionService) {
      
    }
    this.Api.BCInserReview(data).subscribe(res => {
      if(res.successful) {
        this.pageShow = false;
        this.date = '' + this.today.getDate() + '/' + this.today.getMonth() + '/' + (this.today.getFullYear() +543);
      }
    })*/
  }
}
