<div class="layout3-wrapper" id="wrapper">
    <div id="page-header-wrapper">
        <div class="container-fluid">
            <div class="row no-gutters">
                <div class="col-auto">
                    <div class="row no-gutters" style="cursor: pointer" routerLink="/">
                        <div class="col-auto"><img class="page-logo" src="assets/img/LogoH.png" /></div>
                        <!-- <div class="col-auto app-title">
                            <span class="app-main-title">กรมการขนส่งทางราง</span><br /><span class="app-sub-title"
                                >Department of Rail Transport</span
                            >
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="page-body-wrapper" style="overflow: auto; justify-content: center">
        <div class="col-12 row">
            <div class="container" style="margin-bottom: 50px">
                <ng-container *ngFor="let item of fdata">
                    <div class="header_text">
                        <h4>{{ item.title }}</h4>
                        <h6 *ngIf="item.detail">{{ item.detail }}</h6>
                    </div>
                    <form [formGroup]="TData">
                        <div class="review_content" *ngIf="pageShow && !IsRepeat">
                            <div class="container row d-flex justify-content-center" style="margin: 0px; padding-bottom: 40px">
                                <ng-container *ngFor="let data of item.data;let i = index">
                                    <div class="col-12 row div_detail" *ngIf="data.type === 'radio'">
                                        <div class="col-12">
                                            <h5 class="required">{{data.title}}</h5>
                                        </div>
                                        <div class="col-12 row text-left text-md-center justify-content-center div_Subdetail">
                                            <ng-container *ngFor="let res of data.value; let j = index">
                                                <div class="col-12 col-md-2">
                                                    <div class="d-inline-flex d-md-block">
                                                        <input type="radio" [id]="'radio' + i.toString() + j.toString()" 
                                                        class="mt-2 mt-md-0 mr-2 mr-md-0 " [formControlName]="data.name"
                                                        [value]="res.value"/>
                                                        <div><label [for]="'radio' + i.toString() + j.toString()">
                                                            {{ res.text }}
                                                        </label></div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <!--<div class="col-12 col-md-2">
                                                <div>มากที่สุด</div>
                                                <div>
                                                    <input type="radio" [formControlName]="data.name" value="5" />
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-2">
                                                <div>มาก</div>
                                                <div>
                                                    <input type="radio" [formControlName]="data.name" value="4" />
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-2">
                                                <div>ปานกลาง</div>
                                                <div>
                                                    <input type="radio" [formControlName]="data.name" value="3" />
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-2">
                                                <div>พอใช้</div>
                                                <div>
                                                    <input type="radio" [formControlName]="data.name" value="2" />
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-2">
                                                <div>ควรปรับปรุง</div>
                                                <div>
                                                    <input type="radio" [formControlName]="data.name" value="1" />
                                                </div>
                                            </div>-->
                                        </div>
                                    </div>
                                    <div class="col-12 row div_detail" *ngIf="data.type === 'textarea'">
                                        <div class="col-12">
                                            <h5>{{data.title}}</h5>
                                        </div>
                                        <div class="col-12 justify-content-center div_Subdetail">
                                            <textarea style="width: 100%; resize: none; border-radius: 5px" rows="4"
                                                [formControlName]="data.name"></textarea>
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="col-12 row div_detail">
                                    <div class="col-12 text-center">
                                        <button (click)="save()" class="review_btn btn_send">ส่งแบบประเมิน</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </ng-container>
                <!--<div class="header_text">
                    <h4>แบบประเมินความพึงพอใจ</h4>
                </div>
                <div class="review_content" *ngIf="pageShow">
                    <form [formGroup]="Data">
                        <div class="container row d-flex justify-content-center" style="margin: 0px; padding-bottom: 40px">
                            <div class="col-12 row div_detail">
                                <div class="col-12">
                                    <h5>1.การให้บริการของพนักงานขับรถ</h5>
                                </div>
                                <div class="col-12 row text-center justify-content-center div_Subdetail">
                                    <div class="col-12 col-md-2">
                                        <div>มากที่สุด</div>
                                        <div>
                                            <input type="radio" formControlName="DriverService" value="5" />
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-2">
                                        <div>มาก</div>
                                        <div>
                                            <input type="radio" formControlName="DriverService" value="4" />
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-2">
                                        <div>ปานกลาง</div>
                                        <div>
                                            <input type="radio" formControlName="DriverService" value="3" />
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-2">
                                        <div>พอใช้</div>
                                        <div>
                                            <input type="radio" formControlName="DriverService" value="2" />
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-2">
                                        <div>ควรปรับปรุง</div>
                                        <div>
                                            <input type="radio" formControlName="DriverService" value="1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 row div_detail">
                                <div class="col-12">
                                    <h5>2.ความพึงพอใจต่อการให้บริการโดยรวม</h5>
                                </div>
                                <div class="col-12 row text-center justify-content-center div_Subdetail">
                                    <div class="col-12 col-md-2">
                                        <div>มากที่สุด</div>
                                        <div>
                                            <input type="radio" formControlName="SatisfactionService" value="5" />
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-2">
                                        <div>มาก</div>
                                        <div>
                                            <input type="radio" formControlName="SatisfactionService" value="4" />
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-2">
                                        <div>ปานกลาง</div>
                                        <div>
                                            <input type="radio" formControlName="SatisfactionService" value="3" />
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-2">
                                        <div>พอใช้</div>
                                        <div>
                                            <input type="radio" formControlName="SatisfactionService" value="2" />
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-2">
                                        <div>ควรปรับปรุง</div>
                                        <div>
                                            <input type="radio" formControlName="SatisfactionService" value="1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 row div_detail">
                                <div class="col-12">
                                    <h5>3.ความคิดเห็น/ข้อเสนอแนะ (ถ้ามี)</h5>
                                </div>
                                <div class="col-12 justify-content-center div_Subdetail">
                                    <textarea
                                        style="width: 100%; resize: none; border-radius: 5px"
                                        rows="4"
                                        formControlName="Feedback"
                                    ></textarea>
                                </div>
                            </div>
                            <div class="col-12 row div_detail">
                                <div class="col-12 text-center">
                                    <button (click)="save()" class="review_btn btn_send">ส่งแบบประเมิน</button>
                                    <button class="review_btn btn_cancel">ยกเลิก</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div> -->
                <div class="review_content row" *ngIf="!pageShow" style="height: 60vh; text-align: center; display: flex; justify-content: center; align-items: center;">
                    <p style="font-size: 25px;">ประเมินความพึงพอใจสำเร็จ <br>
                    วันที่ {{ date }}</p>
                </div>
                <div class="review_content row" *ngIf="IsRepeat" style="height: 60vh; text-align: center; display: flex; justify-content: center; align-items: center;">
                    <p style="font-size: 25px;">{{RepeatMessage}} <br></p>
                </div>
            </div>
        </div>
    </div>
</div>
