import {GetManageLicenseUser} from './../../e-sarabun/system/permission-user/permission-user-model';
import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {Observable, of} from 'rxjs';
import {LoggerService} from './logger.service';
import {ClaimModel, DataCarImg, UserProfile} from '../model/model';
import {AuthService} from './auth.service';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';
import {HttpBackend, HttpClient, HttpParams} from '@angular/common/http';
import {base64ToFile} from './utils/blob.utils';
import {Router} from '@angular/router';
import {retry, tap} from 'rxjs/operators';

// const logger = new LoggerService('ApiService');

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    API_SERVER_ADDRESS = environment.API_SERVER_ADDRESS;
    private httpBackend: HttpClient;
    token: any = '';

    constructor(
        public http: HttpService,
        public auth: AuthService,
        public sanitizer: DomSanitizer,
        handler: HttpBackend,
        private router: Router
    ) {
        this.httpBackend = new HttpClient(handler);
    }

    getSafeUrl(imgDataURL: string = ''): SafeUrl {
        return this.sanitizer.bypassSecurityTrustUrl(imgDataURL);
    }

    base64ToObjectURL(base64Image: string): string {
        const blob = base64ToFile(base64Image);
        return URL.createObjectURL(blob);
    }

    blobToObjectURL(blob): string {
        return URL.createObjectURL(blob);
    }
    objectURLToRevoke(url) {
        return URL.revokeObjectURL(url);
    }

    blobToSafeUrl(blob): SafeUrl {
        return this.getSafeUrl(this.blobToObjectURL(blob));
    }

    blobToBase64(blob): Promise<any> {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
            reader.onloadend = () => {
                resolve(reader.result);
            };
        });
    }

    base64ToBlob(base64Image: string = ''): Blob {
        const split = base64Image.split(',');
        const type = split[0].replace('data:', '').replace(';base64', '');
        const byteString = atob(split[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i += 1) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], {type});
    }

    async getBlob(url: string) {
        return await this.http.httpClient.get(url, {responseType: 'blob'}).toPromise();
    }

    get user(): UserProfile {
        return this.auth.user;
    }

    // put on your params string token at the url as following ?token=your_token
    getToken() {
        let token = localStorage.getItem('DRTToken') ?? '';
        return token;
    }
    getIP() {
        let token = sessionStorage.getItem('DRTIPAddress') ?? '';
        return token;
    }

    setToken(token) {
        localStorage.setItem('DRTToken', token);
    }

    setQueryString(obj) {
        let queryStr = '?';
        let arrStr = [];
        let keys = Object.keys(obj);
        for (let key of keys) {
            arrStr.push(key + '=' + obj[key]);
        }
        return (queryStr += arrStr.join('&'));
    }

    getEmployeeId() {
        let DRTEmployeeId = localStorage.getItem('DRTEmployeeId') ? localStorage.getItem('DRTEmployeeId') : '';
        return DRTEmployeeId;
    }

    setEmployeeId(DRTEmployeeId) {
        localStorage.setItem('DRTEmployeeId', DRTEmployeeId.toString());
    }

    getUserInfo() {
        let userInfo = localStorage.getItem('DRTUserInfo') ?? '';

        return userInfo !== 'undefined' && userInfo ? JSON.parse(userInfo) : null;
    }
    getMenu() {
        let menu = localStorage.getItem('DRTMenu') ?? '';

        return menu !== 'undefined' && menu ? JSON.parse(menu) : [];
    }

    setUserInfo(DRTUserInfo) {
        localStorage.setItem('DRTUserInfo', JSON.stringify(DRTUserInfo));
    }

    setMenu(DRTUserInfo) {
        localStorage.setItem('DRTMenu', JSON.stringify(DRTUserInfo));
    }

    setMenuSarabun(value) {
        localStorage.setItem('DRTMenu_Sarabun', JSON.stringify(value));
    }

    getMenuSarabun(MenuId?, SubMenuId?) {
        let value = localStorage.getItem('DRTMenu_Sarabun') ?? '';
        if (value !== 'undefined' && value && MenuId && SubMenuId) {
            let array = JSON.parse(value);
            let menus = this.groupBy(array, 'MenuId');
            value = menus[MenuId] ? menus[MenuId].find(f => f.SubMenuId == SubMenuId) : {};
        } else {
            value = null;
        }
        return value;
    }

    getMenuSarabun2(MenuId?, SubMenuId?) {
        let value = localStorage.getItem('DRTMenu_Sarabun') ?? '';
        return value !== 'undefined' && value ? JSON.parse(value) : null;
    }
    getMenuECM(MenuId?, array?) {
        let value;
        if (array && MenuId) {
            let menus = this.groupBy(array, 'MenuId');
            value = menus[MenuId] ? menus[MenuId][0] : null;
        } else {
            value = null;
        }
        return value;
    }

    groupBy(xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    }

    setSubDetailMenu(DRTSubDetailMenu) {
        localStorage.setItem('DRTSubDetailMenu', JSON.stringify(DRTSubDetailMenu));
    }

    getSubDetailMenu() {
        let value = localStorage.getItem('DRTSubDetailMenu') ?? null;
        return value ? JSON.parse(value) : null;
    }

    getProgramId() {
        let DRTProgramId = localStorage.getItem('DRTProgramId') ?? '';
        return DRTProgramId;
    }

    setProgramId(DRTProgramId) {
        localStorage.setItem('DRTProgramId', DRTProgramId ? DRTProgramId.toString() : '');
    }

    Login(UserName: any, PassWord: any): Observable<any> {
        return this.http.post('Login', {UserName, PassWord});
    }

    CheckTelForResetPassword(Tel): Observable<any> {
        return this.http.post(`CheckTelForResetPassword?Tel=${Tel}`);
    }

    testHttpError(): Observable<any> {
        let EmployeeId = this.getEmployeeId();
        let ProgramId = this.getProgramId();
        return this.http.post(`Fraser/ED-GetUserInfo`, {EmployeeId, ProgramId});
    }
    EDGetUserInfo(): Observable<any> {
        let EmployeeId = this.getEmployeeId();
        let ProgramId = this.getProgramId();
        return this.http.get(`Fraser/ED-GetUserInfo`, {EmployeeId, ProgramId});
    }
    ECMGetUserInfo(): Observable<any> {
        let EmployeeId = this.getEmployeeId();
        let ProgramId = this.getProgramId();
        return this.http.get(`Fraser/ECM-GetUserInfo`, {EmployeeId, ProgramId}).pipe(retry(5));
    }

    CheckOTP(Tel, OTP): Observable<any> {
        return this.http.get(`CheckTelForResetPassword`, {Tel, OTP});
    }

    ResetPassword(data): Observable<any> {
        return this.http.post(`ResetPassword`, data);
    }

    GetNotification(StaffId): Observable<any> {
        return this.httpBackend.get(this.API_SERVER_ADDRESS + 'asset/Get-Notification', {params: {StaffId}});
    }

    Get_DocumentCliam1(searchData: any = {}): Observable<any> {
        return this.http.get('asset/Get_DocumentCliam1', searchData);
    }

    EDGetNameReport(TypeAction: any): Observable<any> {
        let EmployeeId = this.getEmployeeId();
        return this.http.get(`Fraser/ED-GetNameReport`, {TypeAction, EmployeeId});
    }

    EDGetlistReceivebook(data): Observable<any> {
        let EmployeeId = this.getEmployeeId();
        data = {...data, EmployeeId};
        return this.http.get('Fraser/ED-GetlistReceivebook', data);
    }

    EDGetSignedOnBook(FormId): Observable<any> {
        let EmployeeId = this.getEmployeeId();
        return this.http.get('Fraser/ED-GetSignedOnBook', {FormId, EmployeeId});
    }

    EDGetVariousReports(data): Observable<any> {
        let EmployeeId = this.getEmployeeId();
        data = {...data, EmployeeId};
        return this.http.get('Fraser/ED-GetVariousReports', data);
    }

    EDGetOtherReports(data): Observable<any> {
        let EmployeeId = this.getEmployeeId();
        data = {...data, EmployeeId};
        return this.http.get('Fraser/ED-GetOtherReports', data);
    }
    EDGetGraphReports(SubReportId, Year): Observable<any> {
        return this.http.get('Fraser/ED-GetGraphReports', {SubReportId, Year});
    }

    EDGetTypeReceiptSendReport(SubReportId): Observable<any> {
        return this.http.get('Fraser/ED-GetTypeReceiptSendReport', {SubReportId});
    }

    EDGetForm(FormId): Observable<any> {
        let EmployeeId = this.getEmployeeId();
        return this.http.get('Fraser/ED-GetForm', {FormId, EmployeeId});
    }

    EDGetFormBookById(FormBookId): Observable<any> {
        return this.http.get('Fraser/ED-GetFormBookById', {FormBookId});
    }

    EDGetlistSendbook(data): Observable<any> {
        return this.http.get('Fraser/ED-GetlistSendbook', data);
    }
    EDGetlistDraft(data): Observable<any> {
        let EmployeeId = this.getEmployeeId();
        data = {...data, EmployeeId: EmployeeId};
        return this.http.get('Fraser/ED-GetlistDraft', data);
    }

    EDGetDoctype(): Observable<any> {
        let token = this.getToken();
        return this.http.get(`Fraser/Dropdow?Path=Budget Bureau/&IsAction=0`, {token: token});
    }

    EDListInbox(params): Observable<any> {
        let EmployeeId = this.getEmployeeId();
        return this.http.get('Fraser/ED-ListInbox', {...params, EmployeeId});
    }

    KoListInbox(params): Observable<any> {
        return this.http.get('Fraser/ListInbox', {...params});
    }

    EDGetFormByDoctype(DoctypeId): Observable<any> {
        return this.http.get('Fraser/ED-GetFormByDoctype', {DoctypeId});
    }

    EDGetSubDocType(path): Observable<any> {
        return this.http.get(`Fraser/Dropdow?Path=${path}&IsAction=1`);
    }

    EDGETSubfolder(path): Observable<any> {
        return this.http.get(`Fraser/Dropdow?Path=${path}&IsAction=2`);
    }

    EDGetAllForm(): Observable<any> {
        return this.http.get('Fraser/ED-GetAllForm');
    }

    EDGetTypeReceiptSend(): Observable<any> {
        return this.http.get('Fraser/ED-GetTypeReceiptSend');
    }

    EDGetWID(SigneId, FormValueBookId): Observable<any> {
        return this.http.get('Fraser/ED-GetWID', {SigneId, FormValueBookId});
    }

    EDPreviewDownloadFileFormBlob(data): Observable<any> {
        data.token = this.getToken();
        return this.http.post('Fraser/ED-Preview_DowloadFileForm', data, {
            responseType: 'blob'
        });
    }
    EDPreviewDownloadFileForm(data): Observable<any> {
        data.token = this.getToken();
        return this.http.post('Fraser/ED-Preview_DowloadFileForm', data);
    }

    EDDeleteRefer(ReferId): Observable<any> {
        return this.http.post('Fraser/ED-DeleteRefer', {ReferId});
    }

    EDDowloadFileForm(data) {
        data.token = this.getToken();
        return this.API_SERVER_ADDRESS + 'Fraser/ED-Preview_DowloadFileForm' + this.setQueryString(data);
    }

    EDGetSecretClass(): Observable<any> {
        return this.http.get('Fraser/ED-GetSecretClass');
    }

    KoGetSecretClass(): Observable<any> {
        return this.http.get('Fraser/GetSecretClass');
    }

    EDGetSpeedClass(): Observable<any> {
        return this.http.get('Fraser/ED-GetSpeedClass');
    }

    KoGetSpeedClass(): Observable<any> {
        return this.http.get('Fraser/GetSpeedClass');
    }

    EDGetNews(): Observable<any> {
        let EmployeeId = this.getEmployeeId();
        return this.http.get('Fraser/ED-GetNews', {EmployeeId});
    }

    EDUpdateStatusNews(data): Observable<any> {
        let EmployeeId = this.getEmployeeId();
        return this.http.post('Fraser/ED-UpdateStatusNews', {...data, EmployeeId});
    }

    EDPostEditNews(data): Observable<any> {
        let EmployeeId = this.getEmployeeId();
        return this.http.post('Fraser/ED-PostEditNews', {...data, EmployeeId});
    }

    EDDeleteNews(NewsId): Observable<any> {
        let EmployeeId = this.getEmployeeId();
        return this.http.post('Fraser/ED-DeleteNews', {NewsId, EmployeeId});
    }

    EDGetCountNoti(TypeMenu): Observable<any> {
        let EmployeeId = this.getEmployeeId();
        return this.http.get('Fraser/ED-GetCountNoti', {TypeMenu, EmployeeId});
    }

    EDSocketNoti(TypeMenu): Observable<any> {
        let EmployeeId = this.getEmployeeId();
        return this.http.post('Fraser/ED-SocketNoti', {TypeMenu, EmployeeId});
    }

    MMImportDurableArticles(data?: object): Promise<any> {
        let token = this.getToken();
        return this.http.post(`drt-ManageMaterial/MM-ImportDurableArticles`, {...data, token}).toPromise();
    }

    MMUpdatePhotoMaterial(data?: object): Promise<any> {
        let token = this.getToken();
        // data.append('token', token);
        // const url = `drt-ManageMaterial/MM-UpdatePhotoMaterial`;
        return this.http.post(`drt-ManageMaterial/MM-UpdatePhotoMaterial`, {...data, token}).toPromise();
    }

    MMGetCountNoti(TypeMenu) {
        let EmployeeId = this.getEmployeeId();
        return this.http.get('drt-ManageMaterial/MM-GetCountNoti', {...TypeMenu, EmployeeId});
    }

    MMGetTypeCategory(): Observable<any> {
        return this.http.get('drt-ManageMaterial/MM-GetTypeCategory');
    }

    MMGetAllPurchaseYear(): Observable<any> {
        return this.http.get('drt-ManageMaterial/MM-GetAllPurchaseYear');
    }

    MMGetUnit(): Observable<any> {
        return this.http.get('drt-ManageMaterial/MM-GetUnit');
    }

    MMGetMaterial(data): Observable<any> {
        return this.http.get('drt-ManageMaterial/MM-GetMaterial', {...data});
    }

    MMGetMaterialDetail(data): Observable<any> {
        return this.http.get('drt-ManageMaterial/MM-GetMaterialDetail', {MaterialId: data.MaterialId});
    }

    MMGetHistoryEditMaterial(data): Observable<any> {
        return this.http.get('drt-ManageMaterial/MM-GetHistoryEditMaterial', {MaterialId: data.MaterialId});
    }

    MMDeleteMaterial(MaterialId, StaffId): Observable<any> {
        return this.http.post('drt-ManageMaterial/MM-DeleteMaterial', {MaterialId: MaterialId, UpdatedBy: StaffId});
    }

    MMGetMaterialById(MaterialId): Observable<any> {
        return this.http.get('drt-ManageMaterial/MM-GetMaterialById', {MaterialId: MaterialId});
    }

    MMInsertMaterial(data?: object): Observable<any> {
        return this.http.post('drt-ManageMaterial/MM-InsertMaterial', {...data});
    }

    MMDeleteFile(data?): Observable<any> {
        data.token = this.getToken();
        return this.http.post('drt-ManageMaterial/MM-DeleteFile', {...data});
    }

    MMUpdateMaterial(data?: object): Observable<any> {
        return this.http.post('drt-ManageMaterial/MM-UpdateMaterial', {...data});
    }

    MMGetTypeDurableArticles(): Observable<any> {
        return this.http.get('drt-ManageMaterial/MM-GetTypeDurableArticles');
    }

    MMGetDurableArticles(data): Observable<any> {
        return this.http.get('drt-ManageMaterial/MM-GetDurableArticles', {...data});
    }

    MMGetListDurableArticles(data): Observable<any> {
        return this.http.get('drt-ManageMaterial/MM-GetListDurableArticles', {...data});
    }
    MMGetListDurableArticlesById(data): Observable<any> {
        return this.http.get('drt-ManageMaterial/MM-GetListDurableArticlesById', {MaterialId: data});
    }

    MMGetDurableArticlesDetail(data): Observable<any> {
        return this.http.get('drt-ManageMaterial/MM-GetDurableArticlesDetail', {MaterialId: data.MaterialId});
    }

    MMGetTrackingStatusBP(data: any = {}): Observable<any> {
        data.EmployeeId = this.getEmployeeId();
        return this.http.get('drt-ManageMaterial/MM-GetTrackingStatusBP', data);
    }

    MMGetReportDurableArticles(data): Observable<any> {
        return this.http.get('drt-ManageMaterial/MM-GetReportDurableArticles', data);
    }

    MMDeleteDurableArticles(MaterialId, StaffId): Observable<any> {
        return this.http.post('drt-ManageMaterial/MM-DeleteDurableArticles', {MaterialId: MaterialId, UpdatedBy: StaffId});
    }

    MMGetDurableArticlesById(MaterialId): Observable<any> {
        return this.http.get('drt-ManageMaterial/MM-GetDurableArticlesById', {MaterialId: MaterialId});
    }

    MMInsertDurableArticles(data?: object): Observable<any> {
        return this.http.post('drt-ManageMaterial/MM-InsertDurableArticles', {...data});
    }

    MMInsertShoppingCart(data: object): Observable<any> {
        return this.http.post('drt-ManageMaterial/MM-InsertShoppingCart', {...data});
    }

    MMUpdateDurableArticles(data?: object): Observable<any> {
        return this.http.post('drt-ManageMaterial/MM-UpdateDurableArticles', {...data});
    }

    MMGetAllPosition(): Observable<any> {
        return this.http.get('drt-ManageMaterial/MM-GetAllPosition');
    }

    MMGetPossessorName(data): Observable<any> {
        return this.http.get('drt-ManageMaterial/MM-GetPossessorName', data);
    }

    MMGetAllDivision(): Observable<any> {
        return this.http.get('drt-ManageMaterial/MM-GetAllDivision');
    }

    MMGetAllSubDivision(DivisionId): Observable<any> {
        return this.http.get('drt-ManageMaterial/MM-GetAllSubDivision', {DivisionId});
    }
    MMGetShoppingCartD(data): Observable<any> {
        return this.http.get('drt-ManageMaterial/MM-GetShoppingCartD', data);
    }

    MMGetStatusMaterial(): Observable<any> {
        return this.http.get('drt-ManageMaterial/MM-GetStatusMaterial');
    }

    MMGetReclaimDocument(): Observable<any> {
        let EmployeeId = this.getEmployeeId();
        return this.http.get('drt-ManageMaterial/MM-GetReclaimDocument', {EmployeeId});
    }

    MMGetStatusDisbursement(): Observable<any> {
        return this.http.get('drt-ManageMaterial/MM-GetStatusDisbursement');
    }
    MMGetStatusReclaim(): Observable<any> {
        return this.http.get('drt-ManageMaterial/MM-GetStatusReclaim');
    }
    MMGetParcelBorrowHistory(data?): Observable<any> {
        let EmployeeId = this.getEmployeeId();
        return this.http.get('drt-ManageMaterial/MM-GetParcelBorrowHistory', {...data, EmployeeId});
    }
    MMGetParcelReclaimHistory(data?): Observable<any> {
        let EmployeeId = this.getEmployeeId();
        return this.http.get('drt-ManageMaterial/MM-GetParcelReclaimHistory', {...data, EmployeeId});
    }

    MMGetTotalCartD(id) {
        return this.http.get('drt-ManageMaterial/MM-GetTotalCartD', {EmployeeId: id});
    }

    MMUpdateStatusShoppingCart(data) {
        return this.http.post('drt-ManageMaterial/MM-UpdateStatusShoppingCart', {...data}).toPromise();
    }

    MMGetOperationStep(data) {
        let EmployeeId = this.getEmployeeId();
        return this.http.get('drt-ManageMaterial/MM-GetOperationStep', {RequestNo: data, EmployeeId});
    }

    MMCancelBorrowParcel(data) {
        data.token = this.getToken();
        return this.http.post('drt-ManageMaterial/MM-CancelBorrowParcel', {...data});
    }

    MMGetRepairHistory(params) {
        params.EmployeeId = this.getEmployeeId();
        return this.http.get('drt-ManageMaterial/MM-GetRepairHistory', {...params});
    }

    MMGetStatusRepair(params) {
        return this.http.get('drt-ManageMaterial/MM-GetStatusRepair', {...params});
    }

    MMGetUserInfo(params) {
        return this.http.get('drt-ManageMaterial/MM-GetUserInfo', {...params});
    }

    test(id = ''): Observable<any> {
        return of([{}, {}]);
    }

    testGet(id = ''): Observable<any> {
        return of({
            successful: true,
            data: [{}, {}]
        });
    }

    EDGetTypeReceiptBook() {
        let EmployeeId = this.getEmployeeId();
        return this.http.get('Fraser/ED-GetTypeReceiptBook', {EmployeeId});
    }

    EDGetTypeReceipt() {
        return this.http.get('Fraser/ED-GetTypeReceipt');
    }

    EDSearchaAgency() {
        return this.http.get('Fraser/ED-SearchaAgency');
    }

    EDUpdateNoti(ProcessId) {
        return this.http.post('Fraser/ED-UpdateNoti', {ProcessId});
    }

    EDUpdateNotiInbox(data?) {
        return this.http.post('Fraser/ED-UpdateNotiInbox', {...data});
    }

    EDGetProvince() {
        return this.http.get('Fraser/ED-GetProvince');
    }

    EDGetDistrict(ProvinceId = '') {
        return this.http.get('Fraser/ED-GetDistrict', {ProvinceId});
    }

    EDGetSubDistrict(DistrictId = '') {
        return this.http.get('Fraser/ED-GetSubDistrict', {DistrictId});
    }

    EDGetAllReserveBook(SearchData): Observable<any> {
        let EmployeeId = this.getEmployeeId();
        SearchData = {...SearchData, EmployeeId};
        return this.http.get('Fraser/ED-GetAllReserveBook', SearchData);
    }

    EDGetOperationStatus(SID = '', DocumentdetailId = '', TypeReceiptBookId = '') {
        return this.http.get('Fraser/ED-GetOperationStatus', {SID, DocumentdetailId, TypeReceiptBookId});
    }

    EDGetAmountBook(TypeReceiptBookId, SID) {
        return this.http.get('Fraser/ED-GetAmountBook', {TypeReceiptBookId, SID});
    }

    EDGetDetailFormbook(TypeReceiptBookId, EmployeeId) {
        return this.http.get('Fraser/ED-GetDetailFormbook', {TypeReceiptBookId, EmployeeId});
    }

    EDGetAttachmentFormBook(TypeReceiptBookId, FormValueBookId, EmployeeId) {
        return this.http.get('Fraser/ED-GetAttachmentFormBook', {TypeReceiptBookId, FormValueBookId, EmployeeId});
    }

    EDDetailReceipt(params?) {
        let EmployeeId = this.getEmployeeId();
        return this.http.get('Fraser/ED-DetailReceipt', {...params, EmployeeId});
    }

    EDGetListExternal(params?) {
        return this.http.get('Fraser/ED-GetListExternal', {...params});
    }

    EDDetailReceiptExternal(params?) {
        let EmployeeId = this.getEmployeeId();
        return this.http.get('Fraser/ED-DetailReceiptExternal', {...params, EmployeeId});
    }

    EDPreview_DowloadFile(data) {
        data.token = this.getToken();
        return this.http.httpClient.post(this.API_SERVER_ADDRESS + 'Fraser/ED-Preview_DowloadFile', this.http.setFormData(data), {
            responseType: 'blob'
        });
    }

    EDPreviewOriginalDocument(data?) {
        let token = this.getToken();
        return this.http.httpClient.post(
            this.API_SERVER_ADDRESS + 'Fraser/ED-PreviewOriginalDocument',
            this.http.setFormData({...data, token}),
            {
                responseType: 'blob'
            }
        );
    }

    EDPreviewFileForm(FormBookId) {
        let token = this.getToken();
        return this.http.httpClient.post(this.API_SERVER_ADDRESS + 'Fraser/ED-PreviewFileForm', this.http.setFormData({FormBookId, token}));
    }

    EDPreviewFileFormBlob(FormBookId) {
        let token = this.getToken();
        return this.http.httpClient.post(
            this.API_SERVER_ADDRESS + 'Fraser/ED-PreviewFileForm',
            this.http.setFormData({FormBookId, token}),
            {responseType: 'blob'}
        );
    }

    EDDownloadFileForm(data) {
        data.token = this.getToken();
        return this.API_SERVER_ADDRESS + 'Fraser/ED-DownloadFileForm' + this.setQueryString(data);
    }

    EDDeleteAttachment(data) {
        data.token = this.getToken();
        return this.http.post('Fraser/ED-DeleteAttachment', data);
    }

    EDGetActionWorkflow(SID) {
        let EmployeeId = this.getEmployeeId();
        return this.http.get('Fraser/ED-GetActionWorkflow', {SID, EmployeeId});
    }

    EDGetOrderDetail(SID) {
        let EmployeeId = this.getEmployeeId();
        return this.http.get('Fraser/ED-GetOrderDetail', {SID, EmployeeId});
    }

    EDGetOrder() {
        return this.http.get('Fraser/ED-GetOrder');
    }

    EDGetTypePage(ActionKeyword, SID) {
        let EmployeeId = this.getEmployeeId();
        return this.http.get('Fraser/ED-GetTypePage', {ActionKeyword, EmployeeId, SID});
    }
    EDGetReserveById(params) {
        return this.http.get('Fraser/ED-GetReserveById', {...params});
    }

    EDPostReserveNoBook(data) {
        let EmployeeId = this.getEmployeeId();
        return this.http.post('Fraser/ED-PostReserveNoBook', {...data, EmployeeId});
    }

    EDPostReserveSendBook(data) {
        let EmployeeId = this.getEmployeeId();
        let token = this.getToken();
        return this.http.post('Fraser/ED-PostReserveSendBook', {...data, EmployeeId, token});
    }

    EDPostReserveSendBookPaper(data) {
        let EmployeeId = this.getEmployeeId();
        let token = this.getToken();
        return this.http.post('Fraser/ED-PostReserveSendBookPaper', {...data, EmployeeId, token});
    }

    EDGetReservePaper(data) {
        let EmployeeId = this.getEmployeeId();
        let token = this.getToken();
        return this.http.get('Fraser/ED-GetReservePaper', {...data, EmployeeId, token});
    }

    EDPostOrderDetail(data) {
        let token = this.getToken();
        data.EmployeeId = this.getEmployeeId();
        return this.http.post('Fraser/ED-PostOrderDetail', {...data, token});
    }

    EDGetSignatureById(EmployeeId) {
        return this.http.get('Fraser/ED-GetSignatureById', {EmployeeId});
    }

    EDUnlockSignature(Password, SignatureId, EmployeeId) {
        return this.http.get('Fraser/ED-UnlockSignature', {Password, SignatureId, EmployeeId});
    }

    EDApproveWorkFlow(data) {
        return this.http.post('Fraser/ED-ApproveWorkFlow', {...data});
    }

    EDTrigger(data) {
        return this.http.post('Fraser/ED-Trigger', {...data});
    }

    EDPostApprove(data) {
        return this.http.post('Fraser/ED-PostApprove', {...data});
    }

    EDApproveSendFlow(data) {
        return this.http.post('Fraser/ED-ApproveSendFlow', {...data});
    }

    EDSendFileExternal(data) {
        return this.http.post('Fraser/ED-SendFileExternal', {...data});
    }

    EDPostMainInternal(data) {
        return this.http.post('Fraser/ED-PostMainInternal', {...data});
    }

    EDCreateRequestFlow(data) {
        return this.http.post('Fraser/ED-CreateRequestFlow', {...data});
    }

    EDPostDocumentDetail(data) {
        return this.http.post('Fraser/ED-PostDocumentDetail', {...data});
    }

    EDPostSignatureApprove(data) {
        return this.http.post('Fraser/ED-PostSignatureApprove', data);
    }

    EDAssignWorkers(data) {
        return this.http.post('Fraser/ED-AssignWorkers', data);
    }

    ECMSaveFileScan(data = {}) {
        return this.http.post('Fraser/ECM-SaveFileScan', data);
    }

    EDSaveFormFile(data): Observable<any> {
        data.EmployeeId = this.getEmployeeId();
        data.token = this.getToken();
        return this.http.post('Fraser/ED-SaveFormFile', {...data});
    }

    EDSaveFileScan(data = {}) {
        return this.http.post('Fraser/ECM-SaveFileScan', data);
    }

    EDPostMainFile(data = {}) {
        return this.http.post('Fraser/ED-PostMainFile', data);
    }

    EDPostMainFileScan(data = {}) {
        return this.http.post('Fraser/ED-PostMainFileScan', data);
    }

    EDCreateRequest(data = {}) {
        return this.http.post('Fraser/ED-CreateRequest', data);
    }

    EDCreateRequestExternal(data = {}) {
        return this.http.post('Fraser/ED-CreateRequestExternal', data);
    }

    EDReadQrcode_BarCode(data = {}) {
        return this.http.post('Fraser/ED-ReadQrcode_BarCode', data);
    }

    EDPostQrCode_Barcode(ImportDocumentId, DetailQr, ImportfileId?) {
        let EmployeeId = this.getEmployeeId();
        return this.http.post('Fraser/ED-PostQrCode_Barcode', {ImportDocumentId, DetailQr, ImportfileId, EmployeeId});
    }

    ReadQrcode_BarCode(data = {}) {
        return this.http.post('drt/ReadQrcode_BarCode', {...data});
    }

    EDPostImportDetail(data) {
        let EmployeeId = this.getEmployeeId();
        data.EmployeeId = EmployeeId;
        return this.http.post('Fraser/ED-PostImportDetail', data);
    }

    EDGetReserveBook(data) {
        let EmployeeId = this.getEmployeeId();
        return this.http.get('Fraser/ED-GetReserveBook', {...data, EmployeeId});
    }
    EDPreviewForm(FormId, form_data, form_id, FormJsonId) {
        return this.http.post('Fraser/ED-PreviewForm', {FormId, form_id, FormJsonId, form_data});
    }

    EDGetReserveReceiptBook(data) {
        let EmployeeId = this.getEmployeeId();
        data.EmployeeId = EmployeeId;
        return this.http.get('Fraser/ED-GetReserveReceiptBook', data);
    }

    EDGetPastReserveNo(data) {
        let EmployeeId = this.getEmployeeId();
        data.EmployeeId = EmployeeId;
        return this.http.get('Fraser/ED-GetPastReserveNo', data);
    }

    EDPostReserveNo(data) {
        let EmployeeId = this.getEmployeeId();
        data.EmployeeId = EmployeeId;
        return this.http.post('Fraser/ED-PostReserveNo', data);
    }

    EDPostPastReserveNo(data) {
        let EmployeeId = this.getEmployeeId();
        data.EmployeeId = EmployeeId;
        return this.http.post('Fraser/ED-PostPastReserveNo', data);
    }
    EDPostSignature(data) {
        return this.http.post('Fraser/ED-PostSignature', data).toPromise();
    }

    EDGetCurrentBookNumber(data) {
        let EmployeeId = this.getEmployeeId();
        data.EmployeeId = EmployeeId;
        return this.http.get('Fraser/ED-GetCurrentBookNumber', data);
    }

    EDGetAllSendBook(data) {
        let EmployeeId = this.getEmployeeId();
        data.EmployeeId = EmployeeId;
        return this.http.get('Fraser/ED-GetAllSendBook', data);
    }

    EDUpdateCancelReserve(data) {
        return this.http.post('Fraser/ED-UpdateCancelReserve', data);
    }

    EDGetDivision() {
        return this.http.get('Fraser/ED-GetDivision');
    }

    EDDeletePermission(data) {
        return this.http.post('Fraser/ED-DeletePermission', data);
    }

    EDApprovalDocument(params: any) {
        params.EmployeeId = this.getEmployeeId();
        return this.http.get('Fraser/ED-ApprovalDocument', params);
    }

    EDGetSubDivision(DivisionId) {
        return this.http.get('Fraser/ED-GetSubDivision', {DivisionId: DivisionId});
    }

    EDGetSubDivisionAdmin(DivisionId) {
        let EmployeeId = this.getEmployeeId();
        return this.http.get('Fraser/ED-GetSubDivisionAdmin', {DivisionId: DivisionId, EmployeeId: EmployeeId});
    }

    EDGetPosition() {
        return this.http.get('Fraser/ED-GetPosition');
    }

    EDGetActingPosition() {
        return this.http.get('Fraser/ED-GetActingPosition');
    }

    EDGetEmployeeById(data) {
        data.EmployeeId = this.getEmployeeId();
        return this.http.get('Fraser/ED-GetEmployeeById', data);
    }

    EDGetAllEmployee(data) {
        data.EmployeeId = this.getEmployeeId();
        return this.http.get('Fraser/ED-GetAllEmployee', data);
    }

    EDGetEmployeeDivision(data) {
        data.EmployeeId = this.getEmployeeId();
        return this.http.get('Fraser/ED-GetEmployeeDivision', data);
    }

    EDGetEmployee(data) {
        data.EmployeeId = this.getEmployeeId();
        return this.http.get('Fraser/ED-GetEmployee', data);
    }

    //insertSimpleLog(form: string, type: string, param: any) {}
    EDUploadAttachmentReceipt(data) {
        return this.http.post('Fraser/ED-UploadAttachmentReceipt', data);
    }

    EDUploadAttachment(data) {
        return this.http.post('Fraser/ED-UploadAttachment', data);
    }

    EDInsertSendBook(data) {
        data.EmployeeId = this.getEmployeeId();
        return this.http.post('Fraser/ED-InsertSendBook', data);
    }
    EDInsertDraft(data) {
        data.EmployeeId = this.getEmployeeId();
        return this.http.post('Fraser/ED-InsertDraft', data);
    }
    EDUpdateStatusDraf(data) {
        // data.EmployeeId = this.getEmployeeId();
        return this.http.post('Fraser/ED-UpdateStatusDraf', data);
    }

    EDDeleteBook(FormBookId) {
        let EmployeeId = this.getEmployeeId();
        return this.http.post('Fraser/ED-DeleteBook', {FormBookId, EmployeeId});
    }

    EDCopyDraft(FormBookId, FormJSON) {
        let EmployeeId = this.getEmployeeId();
        return this.http.post('Fraser/ED-CopyDraft', {FormBookId, FormJSON, EmployeeId});
    }

    EDDeleteDraf(FormBookId) {
        let EmployeeId = this.getEmployeeId();
        return this.http.post('Fraser/ED-DeleteDraf', {FormBookId, EmployeeId});
    }

    EDCreateRequestBook(data) {
        data.EmployeeId = this.getEmployeeId();
        data.token = this.getToken();
        return this.http.post('Fraser/ED-CreateRequestBook', data);
    }

    EDCreateRequestBookPaper(data) {
        data.EmployeeId = this.getEmployeeId();
        data.token = this.getToken();
        return this.http.post('Fraser/ED-CreateRequestBookPaper', data);
    }

    EDInsertTypeSendBook(TypeReceiptId, TypeReceipt, TypeReceiptBookId, SecretClassId = '', SpeedClassId = '', CircularNotice = '') {
        let EmployeeId = this.getEmployeeId();
        return this.http.post('Fraser/ED-InsertTypeSendBook', {
            TypeReceiptId,
            TypeReceipt,
            TypeReceiptBookId,
            EmployeeId,
            SecretClassId,
            SpeedClassId,
            CircularNotice: CircularNotice ? CircularNotice : 0
        });
    }

    EDUpdateFormBook(FormBookId, FormValueBookId, SecretClassId, SpeedClassId) {
        let EmployeeId = this.getEmployeeId();
        let token = this.getToken();
        return this.http.post('Fraser/ED-UpdateFormBook', {FormBookId, FormValueBookId, EmployeeId, token});
    }

    EDUpdateFormValueBook(TypeReceiptBookId, FormValueBookId, SecretClassId, SpeedClassId) {
        let EmployeeId = this.getEmployeeId();
        let token = this.getToken();
        return this.http.post('Fraser/ED-UpdateFormValueBook', {TypeReceiptBookId, FormValueBookId, EmployeeId, token});
    }

    EDUploadAttachmentSendBook(data) {
        data.EmployeeId = this.getEmployeeId();
        data.token = this.getToken();
        return this.http.post('Fraser/ED-UploadAttachmentSendBook', data);
    }

    EDUploadAttachmentSendPaper(data) {
        data.EmployeeId = this.getEmployeeId();
        data.token = this.getToken();
        return this.http.post('Fraser/ED-UploadAttachmentSendPaper', data);
    }

    EDDeleteForm(FormJsonId: any): Observable<any> {
        return this.http.post('Fraser/ED-DeleteForm', {FormJsonId});
    }

    EDUpdateNameActiveForm(FormId: number, FormJsonId: any, FormName: any): Observable<any> {
        return this.http.post('Fraser/ED-UpdateNameActiveForm', {FormId, FormJsonId, FormName});
    }

    EDGetManageLicenseUser(data?: GetManageLicenseUser): Observable<any> {
        return this.http.get('Fraser/ED-GetManageLicenseUser', data);
    }

    EDGetStatusActive(): Observable<any> {
        return this.http.get('Fraser/ED-GetStatusActive');
    }

    EDGetLicense(): Observable<any> {
        return this.http.get('Fraser/ED-GetLicense');
    }

    EDGetTitleName(): Observable<any> {
        return this.http.get('Fraser/ED-GetTitleName');
    }

    EDUpdateManageLicenseUser(data?: object): Observable<any> {
        return this.http.post('Fraser/ED-UpdateManageLicenseUser', data);
    }

    EDGetAgency(): Observable<any> {
        return this.http.get('Fraser/ED-GetAgency');
    }

    EDGetInLineWork(PositionId = '') {
        return this.http.get('Fraser/ED-GetInLineWork', {PositionId});
    }
    EDGetPositionlevel() {
        return this.http.get('Fraser/ED-GetPositionlevel');
    }

    EDInsertManageLicenseUser(data) {
        return this.http.post('Fraser/ED-InsertManageLicenseUser', {...data}).toPromise();
    }

    EDGetManageUsersById(EmployeeId = '') {
        return this.http.get('Fraser/ED-GetManageUsersById', {EmployeeId}).toPromise();
    }

    EDDeleteUser(data: object): Promise<any> {
        return this.http.post('Fraser/ED-DeleteUser', {...data}).toPromise();
    }

    EDUpdateManageUser(data?: object): Promise<any> {
        return this.http.post('Fraser/ED-UpdateManageUser', {...data}).toPromise();
    }
    EDCheckPassword(data: object): Promise<any> {
        return this.http.post('Fraser/ED-CheckPassword', {...data}).toPromise();
    }
    EDDeleteSignature(data?: object): Promise<any> {
        return this.http.post('Fraser/ED-DeleteSignature', {...data}).toPromise();
    }

    postManageDelete(data?: object): Promise<any> {
        return this.http.post('Fraser/ED-ManageDelete', {...data}).toPromise();
    }

    manageGetActiveStatus(): Promise<any> {
        return this.http.get('Fraser/ED-ManageGetActiveStatus').toPromise();
    }

    manageGetAllLicense(data?): Promise<any> {
        return this.http.get('Fraser/ED-ManageGetAllLicense', data).toPromise();
    }

    getManageGetLicenseById(data?): Promise<any> {
        return this.http.get('Fraser/ED-ManageGetLicenseById', data).toPromise();
    }

    getManageGetAllmenu(data?): Promise<any> {
        return this.http.get('Fraser/ED-ManageGetAllmenu', data).toPromise();
    }

    getManageGetAllSubMenu(data?): Promise<any> {
        return this.http.get('Fraser/ED-ManageGetAllSubMenu', data).toPromise();
    }

    getManageGetAllsubmenu_Detail(data?): Promise<any> {
        return this.http.get('Fraser/ED-ManageGetAllsubmenu_Detail', data).toPromise();
    }

    getManageGetAllsubmenu_SubDetail(data?): Promise<any> {
        return this.http.get('Fraser/ED-ManageGetAllsubmenu_SubDetail', data).toPromise();
    }

    postManageUpdateLicenseMenu(data?: object): Promise<any> {
        return this.http.post('Fraser/ED-ManageUpdateLicenseMenu', {...data}).toPromise();
    }

    postManageUpdateLicenseSubMenu(data?: object): Promise<any> {
        return this.http.post('Fraser/ED-ManageUpdateLicenseSubMenu', {...data}).toPromise();
    }

    postManageUpdateLicenseSubMenu_detail(data?: object): Promise<any> {
        return this.http.post('Fraser/ED-ManageUpdateLicenseSubMenu_detail', {...data}).toPromise();
    }
    postManageUpdateLicense(data?: object): Promise<any> {
        return this.http.post('Fraser/ED-ManageUpdateLicense', {...data}).toPromise();
    }
    EDPostEditPosition(data?: object): Promise<any> {
        return this.http.post('Fraser/ED-PostEditPosition', {...data}).toPromise();
    }
    EDPostEditPermision(data?: object): Promise<any> {
        return this.http.post('Fraser/ED-PostEditPermision', {...data}).toPromise();
    }
    getDivision(data?: object): Promise<any> {
        return this.http.get('Fraser/ED-GetDivision', data).toPromise();
    }
    getAllDivision(data?: object): Promise<any> {
        return this.http.get('Fraser/ED-GetAllDivision', data).toPromise();
    }
    EDGetAllPosition(data?: object): Promise<any> {
        return this.http.get('Fraser/ED-GetAllPosition', data).toPromise();
    }
    EDGetAllExternalAgency(data?: object): Promise<any> {
        return this.http.get('Fraser/ED-GetAllExternalAgency', data).toPromise();
    }
    EDPostEditExternalAgency(data?: object): Promise<any> {
        return this.http.post('Fraser/ED-PostEditExternalAgency', data).toPromise();
    }
    EDDeleteExternalAgency(data?: object): Promise<any> {
        return this.http.post('Fraser/ED-DeleteExternalAgency', data).toPromise();
    }
    getSubDivision(data?: object): Promise<any> {
        return this.http.get('Fraser/ED-GetSubDivision', data).toPromise();
    }
    getAllSubDivision(data?: object): Promise<any> {
        return this.http.get('Fraser/ED-GetAllSubDivision', data).toPromise();
    }
    postInsertDivision(data?: object): Promise<any> {
        return this.http.post('Fraser/ED-InsertDivision', {...data}).toPromise();
    }
    postDeleteDivision(data?: object): Promise<any> {
        return this.http.post('Fraser/ED-DeleteDivision', {...data}).toPromise();
    }
    postUpdateSwitchDivision(data?: object): Promise<any> {
        return this.http.post('Fraser/ED-UpdateSwitchDivision', {...data}).toPromise();
    }
    postUpdateDivision(data?: object): Promise<any> {
        return this.http.post('Fraser/ED-UpdateDivision', {...data}).toPromise();
    }

    postUpdateSwitchSubDivision(data?: object): Promise<any> {
        return this.http.post('Fraser/ED-UpdateSwitchSubDivision', {...data}).toPromise();
    }
    postUpdateSubDivision(data?: object): Promise<any> {
        return this.http.post('Fraser/ED-UpdateSubDivision', {...data}).toPromise();
    }
    postInsertSubDivision(data?: object): Promise<any> {
        return this.http.post('Fraser/ED-InsertSubDivision', {...data}).toPromise();
    }
    postDeleteSubDivision(data?: object): Promise<any> {
        return this.http.post('Fraser/ED-DeleteSubDivision', {...data}).toPromise();
    }
    EDDeletePosition(data?: object): Promise<any> {
        return this.http.post('Fraser/ED-DeletePosition', {...data}).toPromise();
    }
    EDUpdateStatusPosition(data?: object): Promise<any> {
        return this.http.post('Fraser/ED-UpdateStatusPosition', {...data}).toPromise();
    }
    EDUpdateStatusExternalAgency(data?: object): Promise<any> {
        return this.http.post('Fraser/ED-UpdateStatusExternalAgency', {...data}).toPromise();
    }
    getYear(data?: object): Promise<any> {
        return this.http.get('Fraser/ED-GetYear', data).toPromise();
    }
    getDoctype(data?: object): Promise<any> {
        return this.http.get('Fraser/ED-GetDoctype', data).toPromise();
    }
    getHoliday(data?: object): Promise<any> {
        return this.http.get('Fraser/ED-GetHoliday', data).toPromise();
    }
    PSCheckHoliday(data?: object): Promise<any> {
        return this.http.get('DRT-PersonnelSystem/PS-CheckHoliday', data).toPromise();
    }
    getHolidayById(data?: object): Promise<any> {
        return this.http.get('Fraser/ED-GetHolidayById', data).toPromise();
    }
    EDGetMenuED(): Promise<any> {
        return this.http.get('Fraser/ED-GetMenuED').toPromise();
    }
    EDGetLog(data?: object): Promise<any> {
        return this.http.get('Fraser/ED-GetLog', {...data}).toPromise();
    }
    getCalendarHoliday(data?: object): Promise<any> {
        return this.http.get('Fraser/ED-GetCalendarHoliday', data).toPromise();
    }
    postDeleteholiday(data?: object): Promise<any> {
        return this.http.post('Fraser/ED-Deleteholiday', {...data}).toPromise();
    }
    insertHoliday(data?: object): Promise<any> {
        return this.http.post('Fraser/ED-InsertHoliday', {...data}).toPromise();
    }
    insertDocTypeHoliday(data?: object): Promise<any> {
        return this.http.post('Fraser/ED-InsertDocTypeHoliday', {...data}).toPromise();
    }
    updateholiday(data?: object): Promise<any> {
        return this.http.post('Fraser/ED-Updateholiday', {...data}).toPromise();
    }
    updateDocTypeHoliday(data?: object): Promise<any> {
        return this.http.post('Fraser/ED-UpdateDocTypeHoliday', {...data}).toPromise();
    }

    EDCheckReserveNoDot(params?: object) {
        return this.http.get('Fraser/ED-CheckReserveNoDot', {...params});
    }

    EDGetOldDoc(): Promise<any> {
        let EmployeeId = this.getEmployeeId();
        return this.http.get('Fraser/ED-GetOldDoc', {EmployeeId}).toPromise();
    }

    //////////// Admin //////////////
    GetAllProgramId_admin(data?): Promise<any> {
        return this.http.get('Fraser/AM-GetAllProgramId', data).toPromise();
    }

    GetAllmenu_admin(data?): Promise<any> {
        return this.http.get('Fraser/AM-GetAllMenu', data).toPromise();
    }

    GetAllSubMenu_admin(data?): Promise<any> {
        return this.http.get('Fraser/AM-GetAllSubMenu', data).toPromise();
    }

    GetAllStatus_admin(data?): Promise<any> {
        return this.http.get('Fraser/AM-GetAllStatus', data).toPromise();
    }

    GetLicenseById_admin(data?): Promise<any> {
        return this.http.get('Fraser/AM-GetLicenseById', data).toPromise();
    }

    UpdateLicense_admin(data?: object): Promise<any> {
        return this.http.post('Fraser/AM-UpdateLicense', {...data}).toPromise();
    }
    CancelManageLicenseProgram_admin(data?): Promise<any> {
        return this.http.post('Fraser/AM-CancelManageLicenseProgram', {...data}).toPromise();
    }
    CancelManageLicenseMenu_admin(data?): Promise<any> {
        return this.http.post('Fraser/AM-CancelManageLicenseMenu', {...data}).toPromise();
    }
    UpdateManageLicenseSubMenu_admin(data?: object): Promise<any> {
        return this.http.post('Fraser/AM-UpdateManageLicenseSubMenu', {...data}).toPromise();
    }
    InsertManageLicenseProgram_admin(data?): Promise<any> {
        return this.http.post('Fraser/AM-InsertManageLicenseProgram', {...data}).toPromise();
    }
    InsertManageLicenseMenu_admin(data?): Promise<any> {
        return this.http.post('Fraser/AM-InsertManageLicenseMenu', {...data}).toPromise();
    }
    InsertManageLicenseSubMenu_admin(data?): Promise<any> {
        return this.http.post('Fraser/AM-InsertManageLicenseSubMenu', {...data}).toPromise();
    }

    MRCheckAudiVisual(params?: any) {
        return this.http.get('drt-MeetingRoom/MR-CheckAudiVisual', {...params});
    }
    MRInsertAttendMeeting(data?: any) {
        data.EmployeeId = this.getEmployeeId();
        return this.http.post('drt-MeetingRoom/MR-InsertAttendMeeting', data);
    }
    MRDeleteAttendMeeting(data?: any) {
        return this.http.post('drt-MeetingRoom/MR-DeleteAttendMeeting', data);
    }
    MRGetListAttendMeeting(FormMeetRoomId) {
        return this.http.get('drt-MeetingRoom/MR-GetListAttendMeeting', {FormMeetRoomId});
    }
    MRGetAllAttendMeeting(params?: any) {
        let EmployeeId = this.getEmployeeId();
        return this.http.get('drt-MeetingRoom/MR-GetAllAttendMeeting', {...params, EmployeeId});
    }
    MRGetMinistry(TypeAgency) {
        return this.http.get('drt-MeetingRoom/MR-GetMinistry', {TypeAgency});
    }
    MRGetAllDivision() {
        return this.http.get('drt-MeetingRoom/MR-GetAllDivision');
    }

    MRGetJoinRegister() {
        let EmployeeId = this.getEmployeeId();
        return this.http.get('drt-MeetingRoom/MR-GetJoinRegister', {EmployeeId});
    }

    MRGetDetailAttendMeeting(FormMeetRoomId) {
        return this.http.get('drt-MeetingRoom/MR-GetDetailAttendMeeting', {FormMeetRoomId});
    }

    MRGetMailAutoSearch(data) {
        return this.http.get('drt-MeetingRoom/MR-GetMailAutoSearch', data);
    }

    MRSendMailAttendees(data) {
        return this.http.post('drt-MeetingRoom/MR-SendMailAttendees', {...data});
    }

    MRPreviewFile(data) {
        return this.http.post('drt-MeetingRoom/MR-PreviewFile', {...data}, {responseType: 'blob'});
    }

    BCInserReview(data) {
        return this.http.post('drt-BookACar/BC-InsertReview', {...data});
    }
    BCCheckReview(params) {
        return this.http.get('drt-BookACar/BC-CheckReview', {...params});
    }

    EDInsertFileRefer(data) {
        return this.http.post('Fraser/ED-InsertFileRefer', {...data});
    }

    MMGetRequestNoById(FormValueMaterialId) {
        return this.http.get('drt-ManageMaterial/MM-GetRequestNoById?FormValueMaterialId=' + FormValueMaterialId);
    }

    MMGetRequestNo() {
        return this.http.get('drt-ManageMaterial/MM-GetRequestNo');
    }

    MMGetTypeCategoryById(data: any) {
        return this.http.get('drt-ManageMaterial/MM-GetTypeCategoryById', {...data});
    }

    MRCreateLink(data) {
        return this.http.get('drt-MeetingRoom/MR-CreateLink', data);
    }
    MRUpdateLink(data) {
        return this.http.post('drt-MeetingRoom/MR-UpdateLink', {...data});
    }

    // MyTask
    AMGetAllProgram(data?): Observable<any> {
        return this.http.get('Fraser/AM-GetAllProgram', data);
    }

    AMGetAllForm(data?): Observable<any> {
        return this.http.get('Fraser/AM-GetAllForm', data);
    }

    AMGetMyWork(data?): Observable<any> {
        return this.http.get('Fraser/AM-GetMyWork', data);
    }

    AMGetMyWorkGroup(data?): Observable<any> {
        return this.http.get('Fraser/AM-GetMyWorkGroup', data);
    }

    AMGetMyWorkHistory(data?): Observable<any> {
        return this.http.get('Fraser/AM-GetMyWorkHistory', data);
    }

    AMGetMyWorkGroupHistory(data?): Observable<any> {
        return this.http.get('Fraser/AM-GetMyWorkGroupHistory', data);
    }

    PSGetAllApplication() {
        return this.http.get('DRT-PersonnelSystem/PS-GetAllApplication');
    }

    PSGetShiftWork() {
        return this.http.get('DRT-PersonnelSystem/PS-GetShiftWork');
    }

    EDGetPreviewSendFile(data) {
        data.token = this.getToken();
        return this.http.post('Fraser/ED-GetPreviewSendFile', data, {responseType: 'blob'});
    }

    getTk() {
        let token = localStorage.getItem('tk') ?? '';
        return token;
    }

    GetAllExternalAgency() {
        //https://dev-sw6-uapi.ecm.in.th/uapi/drt/GetAllExternalAgency
        return this.http.get('drt/GetAllExternalAgency');
    }

    SaveFileExternal(data) {
        //data.token = this.getTk();
        return this.http.post('drt/SaveFileExternal', data);
    }

    GetDoctype(): Observable<any> {
        return this.http.get('drt/GetDoctype');
    }

    GetSubDocType(DoctypeId): Observable<any> {
        return this.http.get('drt/GetSubDocType', {DoctypeId});
    }

    GetSecretClass(): Observable<any> {
        return this.http.get('drt/GetSecretClass');
    }

    GetSpeedClass(): Observable<any> {
        return this.http.get('drt/GetSpeedClass');
    }

    DetailReceiptExternal(params?) {
        let EmployeeId = this.getEmployeeId();
        return this.http.get('drt/DetailReceiptExternal', {...params, EmployeeId});
    }

    DetailReceipt(params?) {
        let EmployeeId = this.getEmployeeId();
        return this.http.get('drt/DetailReceipt', {...params, EmployeeId});
    }

    Preview_DowloadFile(data) {
        data.token = this.getToken();
        return this.http.httpClient.post(this.API_SERVER_ADDRESS + 'drt/Preview_DowloadFile', this.http.setFormData(data), {
            responseType: 'blob'
        });
    }

    DeleteAttachment(data) {
        data.token = this.getToken();
        return this.http.post('drt/DeleteAttachment', data);
    }

    PreviewFileForm(FormBookId) {
        let token = this.getToken();
        return this.http.httpClient.post(this.API_SERVER_ADDRESS + 'drt/PreviewFileForm', this.http.setFormData({FormBookId, token}));
    }

    PreviewFileFormBlob(FormBookId) {
        let token = this.getToken();
        return this.http.httpClient.post(this.API_SERVER_ADDRESS + 'drt/PreviewFileForm', this.http.setFormData({FormBookId, token}), {
            responseType: 'blob'
        });
    }

    DownloadFileForm(data) {
        data.token = this.getToken();
        return this.API_SERVER_ADDRESS + 'drt/DownloadFileForm' + this.setQueryString(data);
    }

    PreviewOriginalDocument(data?) {
        let token = this.getToken();
        return this.http.httpClient.post(this.API_SERVER_ADDRESS + 'drt/PreviewOriginalDocument', this.http.setFormData({...data, token}), {
            responseType: 'blob'
        });
    }

    ApprovalDocument(params: any) {
        params.EmployeeId = this.getEmployeeId();
        return this.http.get('drt/ApprovalDocument', params);
    }

    GetOrder() {
        return this.http.get('drt/GetOrder');
    }

    GetOrderDetail(SID) {
        let EmployeeId = this.getEmployeeId();
        return this.http.get('drt/GetOrderDetail', {SID, EmployeeId});
    }

    GetDivision() {
        return this.http.get('drt/GetDivision');
    }

    GetPosition() {
        return this.http.get('drt/GetPosition');
    }

    GetSubDivision(DivisionId = '') {
        return this.http.get('drt/GetSubDivision', {DivisionId});
    }

    GetTypePage(ActionKeyword, SID) {
        let EmployeeId = this.getEmployeeId();
        return this.http.get('drt/GetTypePage', {ActionKeyword, EmployeeId, SID});
    }

    PostReserveNoBook(data) {
        let EmployeeId = this.getEmployeeId();
        return this.http.post('drt/PostReserveNoBook', {...data, EmployeeId});
    }

    ApproveWorkFlow(data) {
        return this.http.post('drt/ApproveWorkFlow', {...data});
    }

    PostSignatureApprove(data) {
        return this.http.post('drt/PostSignatureApprove', data);
    }

    AssignWorkers(data) {
        return this.http.post('drt/AssignWorkers', data);
    }

    PostOrderDetail(data) {
        let token = this.getToken();
        return this.http.post('drt/PostOrderDetail', {...data, token});
    }

    PostApprove(data) {
        return this.http.post('drt/PostApprove', {...data});
    }

    ApproveSendFlow(data) {
        return this.http.post('drt/ApproveSendFlow', {...data});
    }

    PostReserveSendBook(data) {
        let EmployeeId = this.getEmployeeId();
        let token = this.getToken();
        return this.http.post('drt/PostReserveSendBook', {...data, EmployeeId, token});
    }

    PostMainInternal(data) {
        return this.http.post('drt/PostMainInternal', {...data});
    }

    CreateRequestFlow(data) {
        return this.http.post('drt/CreateRequestFlow', {...data});
    }

    PostDocumentDetail(data) {
        return this.http.post('drt/PostDocumentDetail', {...data});
    }

    PostMainFile(data = {}) {
        return this.http.post('drt/PostMainFile', data);
    }

    CreateRequestExternal(data = {}) {
        return this.http.post('drt/CreateRequestExternal', data);
    }

    PostImportDetail(data) {
        let EmployeeId = this.getEmployeeId();
        data.EmployeeId = EmployeeId;
        return this.http.post('drt/PostImportDetail', data);
    }

    GetEmployeeById(data) {
        data.EmployeeId = this.getEmployeeId();
        return this.http.get('drt/GetEmployeeById', data);
    }

    GetActionWorkflow(SID) {
        let EmployeeId = this.getEmployeeId();
        return this.http.get('drt/GetActionWorkflow', {SID, EmployeeId});
    }

    GetOperationStatus(SID = '', DocumentdetailId = '', TypeReceiptBookId = '') {
        return this.http.get('drt/GetOperationStatus', {SID, DocumentdetailId, TypeReceiptBookId});
    }

    GetAmountBook(TypeReceiptBookId, SID) {
        return this.http.get('drt/GetAmountBook', {TypeReceiptBookId, SID});
    }

    GetDetailFormbook(TypeReceiptBookId, EmployeeId) {
        return this.http.get('drt/GetDetailFormbook', {TypeReceiptBookId, EmployeeId});
    }

    ListInbox(params): Observable<any> {
        return this.http.get('drt/ListInbox', {...params});
    }

    EDGetAllSubDivision(params): Observable<any> {
        return this.http.get('Fraser/ED-GetAllSubDivision', params);
    }

    EDGetEmployeeBySubDivision(data): Observable<any> {
        data.EmployeeId = this.getEmployeeId();
        return this.http.get('Fraser/ED-GetEmployeeBySubDivision', {...data});
    }

    MMPreviewFile(data): Observable<any> {
        data.token = this.getToken();
        return this.http.post('drt-ManageMaterial/MM-PreviewFile', {...data}, {responseType: 'blob'});
    }

    MMPreviewDownloadPDF(data) {
        data.EmployeeId = this.getEmployeeId();
        data.token = this.getToken();
        return this.API_SERVER_ADDRESS + 'drt-ManageMaterial/MM-PreviewDownloadPDF' + this.setQueryString(data);
        // return this.http.get('drt-ManageMaterial/MM-PreviewDownloadPDF', {...data});
    }

    MMPostEditMaterial(data) {
        return this.http.post('drt-ManageMaterial/MM-PostEditMaterial', {...data});
    }

    httpErrorLog(formData): Observable<any> {
        return this.http.post('Fraser/AM-InsertLogError', {...formData}).pipe(retry(1));
    }
    GetReservePaperBook(data) {
        data.EmployeeId = this.getEmployeeId();
        return this.http.get('Fraser/ED-GetReservePaperBook', {...data});
    }
    InsertBookPaper(data) {
        data.CreatedBy = this.getEmployeeId();
        console.log('api insertd', data);
        return this.http.post('Fraser/ED-InsertBookPaper', {...data});
    }
    GetActionCreateFlow(data) {
        data.EmployeeId = this.getEmployeeId();
        return this.http.get('Fraser/ED-GetActionCreateFlow', {...data});
    }
    GetEmployeeCreateFlow(data) {
        data.EmployeeId = this.getEmployeeId();
        return this.http.get('Fraser/ED-GetEmployeeCreateFlow', {...data});
    }

    EDGetActionCreateFlowReceipt(data?: any): Observable<any> {
        data.EmployeeId = this.getEmployeeId();
        return this.http.get('Fraser/ED-GetActionCreateFlowReceipt', {...data});
    }

    //Upload
    setFormData(data: any) {
        data = data ? data : {};
        const formData = new FormData();
        const key = Object.keys(data);
        key.forEach(item => {
            formData.append(item, data[item] === null || data[item] === undefined ? '' : data[item]);
        });
        return formData;
    }

    get_DataTextConsent() {
        return this.http.get('etda/DocTF-GetDataTextConsent');
    }

    DOCTFPostDocTypeFormImage(data) {
        const formData = new FormData();
        //formData.append('File_Input_Stream', data.File_Input_Stream || '');
        formData.append('file_store_id', data.file_store_id || '');
        //formData.append('id_file_input', data.id_file_input || '');
        //formData.append('id_user', data.id_user || '');
        //formData.append('page_no_from_system', data.page_no_from_system || '');
        //formData.append('is_same_doctype', data.is_same_doctype || '');
        //formData.append('page_all', data.page_all || '');
        return this.http.post('etda/DOCTF-PostDocTypeFormImage', formData);
    }

    DocTFAddFileFromUpload(data) {
        const formData = new FormData();
        formData.append('type_file_input', data.typefile || '');
        formData.append('size_file_input', data.sizefile || '');
        formData.append('file_name_input', data.filename || '');
        formData.append('id_user', data.user || '');
        formData.append('document_type', data.document_type || ''); //ex : สำเนา , ต้นฉบับ
        formData.append('is_document_set', data.is_document_set || ''); //ex : 0 = uncheck , 1  = checked
        //formData.append('pageno', data.pageNO || '');
        //formData.append('status_upload', data.filestatus || '');
        //formData.append('time_file_input', data.datetime || '');
        formData.append('file', data.file || '');
        formData.append('id_sub_group', data.id_sub_group);
        formData.append('document_source', data.document_source || '');
        return this.http.post('etda/DocTF-AddFileFromUpload', formData);
    }

    DocTFLogConsent(data) {
        const formData = this.setFormData(data);
        return this.http.post('etda/DocTF-LogConsent', formData);
    }

    DocTFPostDefineByTemplate(data) {
        const formData = this.setFormData(data);
        return this.http.post('etda/DocTF-PostDefineByTemplate', formData);
    }

    DocTFPostDefineByTemplate_main(data) {
        const formData = this.setFormData(data);
        return this.http.post('etda/DocTF-PostDefineByTemplate_main', formData);
    }

    DocTFGetQualityFile(data) {
        return this.http.get('etda/DocTF-GetQualityFile', data);
    }

    DocTFGetDocumentSource() {
        return this.http.get('etda/DocTF-GetDocumentSource');
    }

    DocTFGetResultFromUser(data) {
        return this.http.get('etda/DocTF-GetResultFromUser', data);
    }

    DocTFGetFileOutput(data) {
        return this.http.get('etda/DocTF-GetFileOutput', {...data, responseType: 'blob'});
    }

    DocTFGetResultFromClassify(data) {
        return this.http.get('etda/DocTF-GetResultFromClassify', data);
    }

    DocTFAddFileOutput(data) {
        const formData = this.setFormData(data);
        //const formData = new FormData();
        //formData.append('type_file_output', data.type_file_output || '');
        //formData.append('size_file_output', data.size_file_output || 0);
        //formData.append('name_file_output', data.name_file_output || '');
        //formData.append('pageno', data.pageno);
        //formData.append('doctypeId', data.doctypeId);
        //formData.append('ref_id_file_input', data.ref_id_file_input);
        // formData.append("id_classify", data.id_classify);
        // formData.append("id_file_output", data.id_file_output);
        return this.http.post('etda/DocTF-AddFileOutput', formData);
    }

    DocTFInsertValueMetadataFromOcr(data) {
        const formData = this.setFormData(data);
        // const formData = new FormData();
        // formData.append("id_file_input", data.id_file_input);
        // formData.append("id_file_out", data.id_file_out);
        // formData.append("id_doctype", data.id_doctype);
        return this.http.post('etda/DocTF-InsertValueMetadataFromOcr', formData);
    }

    DocTFGetZoneTemplate(data) {
        return this.http.get('etda/zone_template', data);
    }

    DocTFGetContentType(data) {
        return this.http.get('etda/DocTF-GetContentType', data);
    }

    DocTFUpdateSizeFile(data) {
        const formData = this.setFormData(data);
        return this.http.post('etda/DocTF-UpdateSizeFile', formData);
    }

    DocTFInsertValueMetadataFromUser(data) {
        const formData = new FormData();
        formData.append('id_file_out', data.id_file_out);
        formData.append('id_doctype', data.id_doctype);
        formData.append('id_user', data.id_user);
        formData.append('array_properties', JSON.stringify(data.array_properties));
        formData.append('array_metadata', JSON.stringify(data.array_metadata));
        formData.append('array_table', JSON.stringify(data.array_table));
        return this.http.post('etda/DocTF-InsertValueMetadataFromUser', formData);
    }

    DocTFGetPdfBwandTable(data) {
        return this.http.get('etda/DocTF-GetPdfBwandTable', data);
    }

    DocTFOCRUploadFile(data) {
        const formData = this.setFormData(data);
        return this.http.post('Fraser/OCR-UploadFile', formData);
    }

    DocTFGetResultOcrFromClassify(data) {
        return this.http.get('etda/DocTF-GetResultOcrFromClassify', data);
    }

    DocBBGetSubDoctypeByZoneTemplate(data?: any) {
        return this.http.get('etda/DocBB-GetSubDoctypeByZoneTemplate', data);
    }

    DocTFPdpaField(data) {
        const formData = this.setFormData(data);
        return this.http.post('etda/DocTF-PdpaField', formData);
    }

    DocTFGetClassifyImage(data) {
        return this.http.get('etda/DocTF-GetClassifyImage', data);
    }

    DocTFZoneOcr(data) {
        const formData = this.setFormData(data);
        return this.http.post('etda/DocTF-ZoneOcr', formData);
    }

    DocTFGetDocTypeForUpload() {
        return this.http.get('etda/DocTF-GetDocTypeForUpload');
    }

    DocTFAddClassify(data) {
        const formData = new FormData();
        formData.append('id_classify_doctype', data.typefile || '');
        formData.append('id_doctype_from_user', data.doctype || '');
        formData.append('id_user', data.user || '');
        formData.append('id_sub_group', data.idgroup);
        formData.append('zone_template_id', data.zone_template_id);
        return this.http.post('etda/DocTF-AddClassify', formData);
    }

    DocTFGetOCREngineName() {
        return this.http.get('etda/DocTF-GetOCREngineName');
    }

    DOCTFRemovePage(data) {
        const formData = this.setFormData(data);
        return this.http.post('etda/DOCTF-RemovePage', formData);
    }

    DocTFLogConsentOutput(data) {
        const formData = this.setFormData(data);
        return this.http.post('etda/DocTF-LogConsentOutput', formData);
    }

    async ExtractPDFToImage(file: File): Promise<Blob[]> {
        const formData = this.setFormData({file: file});
        let res = await this.http.post('etda/ExtractPDFToImage', formData).toPromise();
        if (!res.successful) throw new Error(res.message);

        let pages: Blob[] = [];
        for (let base64Data of res.data) {
            let blob = await (await fetch('data:image/png;base64,' + base64Data)).blob();
            pages.push(blob);
        }

        return pages;
    }

    // async Getfiletoscan(data) {
    //     const formData = new FormData();
    //     formData.append('Uuid', data.Uuid || '');
    //     // formData.append('fileName', data.Name || '');
    //     let path = data.Path || '';
    //     let FileName = data.Name || '';
    //     let Uuid = data.Uuid || '';
    //     return this.http.post(`Fraser/Get-filetoscan`, formData).toPromise();
    // }

    Getfiletoscan(data): Observable<Blob> {
        const formData = new FormData();
        formData.append('Uuid', data.Uuid || '');
        formData.append('path', data.Path || '');
        formData.append('FileName', data.Name || '');
        formData.append('Namefile', data.Name || '');
        const options = {responseType: 'blob' as 'json'};
        return this.http.post(
            `Fraser/Get-filetoscan?Uuid=${data.Uuid}&path=${data.Path}&FileName=${data.Name}&Namefile=${data.Name}`,
            formData,
            options
        );
    }

    Getpropertisetoscan(data) {
        let path = data.Path || '';
        let FileName = data.Name || '';
        return this.http.get(`Fraser/Get-propertisetoscan`, {path, FileName});
    }

    FraserGetDoctype() {
        let api = `https://bb-uapi.ecm.in.th/uapi/Fraser/GET-GetDoctype`;
        return this.http.get(api);
    }

    FraserGetSubDocType(data) {
        let api = `https://bb-uapi.ecm.in.th/uapi/Fraser/GET-GetSubDocType`;
        return this.http.get(api, {params: data});
    }

    FraserGetSubfolder(data) {
        let api = `https://bb-uapi.ecm.in.th/uapi/Fraser/GET-Subfolder`;
        return this.http.get(api, {params: data});
    }

    InsertFileTemplate(data) {
        const formData = this.setFormData(data);
        let api = `https://bb-uapi.ecm.in.th/uapi/Fraser/InsertFileTemplate`;
        return this.http.post(api, formData);
    }

    FraserGetDrawer() {
        let token = this.getToken();
        let api = `Fraser/Dropdow?Path=Budget Bureau/&token=${token}&IsAction=0`;
        return this.http.get(api, {token: token});
    }

    FraserGetMasterFolder(data) {
        // let api = `${environment.uapi}/uapi/Fraser/GET-MasterFolder`;
        const url = `Fraser/GET-MasterFolder`;
        return this.http.get(url, {params: data});
    }

    FraserGETFolder() {
        // let api = `${environment.uapi}/uapi/Fraser/GET-Folder`;
        const url = `Fraser/GET-Folder`;
        return this.http.get(url);
    }

    // NLPSpellCheck(data): Observable<any> {
    //     const formData = new FormData();
    //     formData.append('text', data || '');
    //     // return this.http.get(url);
    //     return this.http.get(`etda/NLPSpellCheck`, formData);
    // }

    NLPSpellCheck(data): Observable<any> {
        const formData = new FormData();
        formData.append('text', data || '');

        const url = `etda/NLPSpellCheck`;
        return this.http.post(url, formData, {responseType: 'text'}).pipe(
            tap(response => {
                console.log('API Response:', response);
                // ทำงานเพิ่มเติมตามต้องการ
            })
        );
    }

    DFGetMetadata(data) {
        const url = `etda/DF-GetMetadata`;

        // let api = `${environment.uapi}/uapi/etda/DF-GetMetadata`;
        return this.http.get(url, {Uuid: data});
    }

    UpdateAndCheckIn(uuid: string, file: Blob): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('uuid', uuid);

        return this.http.post('Fraser/UpdateAndCheckIn', formData);
    }

    ECMUpdateAbbyData(uuid, content) {
        const url = `fraser/ECM-UpdateAbbyData`;
        return this.http.post(url, {uuid, content});
    }
}
