import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})

export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private api: ApiService) {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    if (error.status != 401) {
                        let errorMsg = '';
                        let obj = {
                            IPAddress: this.api.getIP() ?? "",
                            Token: this.api.getToken() ?? "",
                            Statusode: error?.status ?? "", // status code
                            MessageError: error?.error?.message ?? error?.message,// error message
                            UrlRequest: error?.url ?? "",
                            CreatedBy: this.api.getEmployeeId() ?? ""
                        }

                        //this.api.httpErrorLog(obj).subscribe();

                        if (error.error instanceof ErrorEvent) {
                            // console.log('this is client side error');
                            errorMsg = `Error: ${error.error.message}`;
                        }
                        else {
                            // console.log('this is server side error');
                            errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
                        }
                        // console.log(errorMsg);
                        return throwError(errorMsg);
                    }
                })
            )
    }
}