<ng-container
    *ngIf="
        alertType == alertTypes.warning ||
        alertType == alertTypes.danger ||
        alertType == alertTypes.success ||
        alertType == alertTypes.error ||
        alertType == alertTypes.info
    "
>
    <div class="modal-body">
        <div>
            <div class="text-right" style="min-height: 30px">
                <button
                    *ngIf="config.canDismiss !== false"
                    type="button"
                    class="btn btn-link"
                    aria-label="Close"
                    (click)="activeModal.dismiss('close')"
                >
                    <i class="far fa-times-circle"></i>
                </button>
            </div>
            <div class="text-center alert-content-wrapper">
                <img *ngIf="alertType == alertTypes.warning" class="alert-icon" src="assets/icon/warning.svg" alt="" />
                <img *ngIf="alertType == alertTypes.danger" class="alert-icon" src="assets/icon/warning.svg" alt="" />
                <img *ngIf="alertType == alertTypes.success" class="alert-icon" src="assets/icon/success.svg" alt="" />
                <img *ngIf="alertType == alertTypes.error" class="alert-icon" src="assets/icon/error.svg" alt="" />
                <img *ngIf="alertType == alertTypes.info" class="alert-icon" src="assets/icon/info.svg" alt="" />
                <div class="alert-content">
                    <div *ngIf="config.title" class="alert-title" [innerHTML]="config.title"></div>
                    <div *ngIf="config.body" class="alert-body" [innerHTML]="config.body"></div>
                </div>
            </div>
        </div>

        <ng-container>
            <div class="text-center modal-button mt-3" *ngIf="config.confirmButton || config.cancelButton">
                <button *ngIf="config.confirmButton" type="button" class="btn btn-primary mx-1" (click)="activeModal.close('confirm')">
                    {{ config.confirmButtonText }}
                </button>

                <button
                    *ngIf="config.cancelButton"
                    type="button"
                    class="btn btn-outline-danger mx-1"
                    (click)="activeModal.dismiss('cancel')"
                >
                    {{ config.cancelButtonText }}
                </button>
            </div>
        </ng-container>
    </div>
</ng-container>

<ng-container *ngIf="alertType == alertTypes.alert">
    <div class="modal-header alert-modal-header">
        <button
            *ngIf="config.canDismiss !== false"
            style="visibility: hidden"
            type="button"
            class="btn btn-link"
            aria-label="Close"
            (click)="activeModal.dismiss('close')"
        >
            <i class="far fa-times-circle"></i>
        </button>
        <h4 class="modal-title mx-auto">{{ config.title }}</h4>
        <button
            *ngIf="config.canDismiss !== false"
            type="button"
            class="btn btn-link"
            aria-label="Close"
            (click)="activeModal.dismiss('close')"
        >
            <i class="far fa-times-circle"></i>
        </button>
    </div>
    <div class="modal-body">
        <div>
            <div class="text-center">
                <!-- <div class="mt-1 mb-4" style="size: 16px; font-weight: 500" [innerHTML]="config.body"></div> -->
                <div class="mt-none mt-xl-2 mb-none mb-xl-4" style="size: 16px; font-weight: 500" [innerHTML]="config.body"></div>
            </div>
            <div class="text-center modal-button mt-3" *ngIf="config.confirmButton || config.cancelButton">
                <button type="button" class="btn btn-primary mx-1" (click)="activeModal.close('confirm')">
                    {{ config.confirmButtonText }}
                </button>
                <button type="button" class="btn btn-danger mx-1" (click)="activeModal.dismiss('cancel')">
                    {{ config.cancelButtonText }}
                </button>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="alertType == alertTypes.delete || alertType == alertTypes.cancel">
    <div class="modal-header alert-modal-confirm bg-danger">
        <h4 class="modal-title">{{ config.title }}</h4>
    </div>
    <div class="modal-body">
        <div>
            <div class="text-center">
                <!-- <img *ngIf="alertType == alertTypes.cancel" src="assets/icon/error.svg" class="alert-icon" /> -->
                <h1 *ngIf="alertType == alertTypes.cancel" class="card-title text-danger"><img src="../../assets/icon/assets_icon/cancel.png" /></h1>
                <!-- <img *ngIf="alertType == alertTypes.delete" src="assets/icon/confirm-del.svg" class="alert-icon" /> -->
                <h1 *ngIf="alertType == alertTypes.delete" class="card-title text-danger"><i class="fas fa-trash-alt"></i></h1>
                <!-- <div class="mt-2 mb-4" style="size: 18px; font-weight: 500" [innerHTML]="config.body"></div> -->
                <div class="mt-none mt-xl-2 mb-none mb-xl-4" style="size: 18px; font-weight: 500" [innerHTML]="config.body"></div>
            </div>
            <div class="text-center modal-button mt-3" *ngIf="config.confirmButton || config.cancelButton">
                <button type="button" class="btn btn-info mx-1" (click)="activeModal.close('confirm')">
                    {{ config.confirmButtonText }}
                </button>
                <button type="button" class="btn btn-danger mx-1" (click)="activeModal.dismiss('cancel')">
                    {{ config.cancelButtonText }}
                </button>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="alertType == alertTypes.copy">
    <div class="modal-header alert-modal-confirm bg-info">
        <h4 class="modal-title">{{ config.title }}</h4>
    </div>
    <div class="modal-body">
        <div>
            <div class="text-center">
                <img *ngIf="alertType == alertTypes.copy" class="alert-icon" src="assets/icon/copy.png" alt="" />
                <!-- <div class="mt-2 mb-4" style="size: 18px; font-weight: 500" [innerHTML]="config.body"></div> -->
                <div class="mt-none mt-xl-2 mb-none mb-xl-4" style="size: 18px; font-weight: 500" [innerHTML]="config.body"></div>
            </div>
            <div class="text-center modal-button mt-3" *ngIf="config.confirmButton || config.cancelButton">
                <button type="button" class="btn btn-primary mx-1" (click)="activeModal.close('confirm')">
                    {{ config.confirmButtonText }}
                </button>
                <button type="button" class="btn btn-danger mx-1" (click)="activeModal.dismiss('cancel')">
                    {{ config.cancelButtonText }}
                </button>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="alertType == alertTypes.content">
    <div class="modal-header alert-modal-header-success" style="padding-bottom: 3px;">
        <h4 class="">{{ config.title }}</h4>
        <button
            *ngIf="config.canDismiss !== false"
            type="button"
            class="btn btn-link d-flex"
            aria-label="Close"
            (click)="activeModal.dismiss('close')"
        >
            <i class="far fa-times-circle"></i>
        </button>
    </div>
    <div class="modal-body">
        <div>
            <div class="text-center">
                <!-- <div class="mt-1 mb-4" style="size: 16px; font-weight: 500" [innerHTML]="config.body"></div> -->
                <div class="mt-none mt-xl-2 mb-none mb-xl-4" style="size: 16px; font-weight: 500">
                <div #content>
                    <div class="row text-left">
                        <div class="col-4">
                            <b>ชื่อเรื่อง</b>
                        </div>
                        <div class="col-8 d-inline-flex">
                            : <label class="ml-2">{{ config.body?.TitleName || '' }}</label>
                        </div>
                    </div>
                    <div class="row text-left">
                        <div class="col-4">
                            <b>เลขที่หนังสือ</b>
                        </div>
                        <div class="col-8 d-inline-flex">
                            : <label class="ml-2">{{ config.body?.NoReceiptDRT || '' }}</label>
                        </div>
                    </div>
                    <div class="row text-left">
                        <div class="col-4">
                            <b>วันที่หนังสือ</b>
                        </div>
                        <div class="col-8 d-inline-flex">
                            : <label class="ml-2">{{ config.body?.Date || '' }}</label>
                        </div>
                    </div>
                    <div class="row text-center mt-3" *ngIf="config.body?.message">
                        <div class="col-12">{{ config.body?.message || 'ได้ดำเนินการลงรับหนังสือสำเร็จ' }} </div>
                    </div>
                </div>
            </div>
        </div>
            <div class="text-center modal-button mt-3" *ngIf="config.confirmButton || config.cancelButton">
                <button type="button" class="btn btn-primary mx-1" (click)="activeModal.close('confirm')">
                    {{ config.confirmButtonText }}
                </button>
                <button type="button" class="btn btn-danger mx-1" (click)="activeModal.dismiss('cancel')">
                    {{ config.cancelButtonText }}
                </button>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="alertType == alertTypes.meet">
    <div class="modal-header alert-modal-header-success" style="padding-bottom: 3px;">
        <h4 class="">{{ config.title }}</h4>
        <button
            *ngIf="config.canDismiss !== false"
            type="button"
            class="btn btn-link d-flex"
            aria-label="Close"
            (click)="activeModal.dismiss('close')"
        >
            <i class="far fa-times-circle"></i>
        </button>
    </div>
    <div class="modal-body">
        <div>
            <div class="text-center mx-5">
                <!-- <div class="mt-1 mb-4" style="size: 16px; font-weight: 500" [innerHTML]="config.body"></div> -->
                <div class="mt-none mt-xl-2 mb-none mb-xl-4" style="size: 16px; font-weight: 500">
                <div #content>
                    <div class="row text-left">
                        <div class="col-4">
                            <b>ลิ้งค์ Zoom</b>
                        </div>
                        <div class="col-8">
                            : <label>{{ config.body?.LinkZoom || '' }}</label>
                        </div>
                    </div>
                    <div class="row text-left">
                        <div class="col-4">
                            <b>Meeting ID</b>
                        </div>
                        <div class="col-8">
                            : <label>{{ config.body?.MeetingId || '' }}</label>
                        </div>
                    </div>
                    <div class="row text-left">
                        <div class="col-4">
                            <b>Passcode</b>
                        </div>
                        <div class="col-8">
                            : <label>{{ config.body?.Passcode || '' }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <div class="text-center modal-button mt-3" *ngIf="config.confirmButton || config.cancelButton">
                <button type="button" class="btn btn-primary mx-1" (click)="activeModal.close('confirm')">
                    {{ config.confirmButtonText }}
                </button>
                <button type="button" class="btn btn-danger mx-1" (click)="activeModal.dismiss('cancel')">
                    {{ config.cancelButtonText }}
                </button>
            </div>
        </div>
    </div>
</ng-container>
