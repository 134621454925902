import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {LoggerService} from './logger.service';
import {TranslateService} from '@ngx-translate/core';

const LANG = 'language';
// const logger = new LoggerService('LanguageService');

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    language$: BehaviorSubject<any> = new BehaviorSubject<any>(environment.DEFAULT_LANGUAGE);

    constructor(private translate: TranslateService) {
        this.translate.setDefaultLang(environment.DEFAULT_LANGUAGE);
    }

    get currentLang() {
        return this.language$.value;
    }
    use(lang: string) {
        this.translate.use(lang);
        localStorage.setItem(LANG, lang);
        this.language$.next(lang);
    }
}
