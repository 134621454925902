import {Injectable, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbModule, NgbTimepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {LoaderComponent} from './shared/loading/loader/loader.component';
import {LoaderService} from './shared/loading/loader.service';
import {LoaderInterceptorService} from './shared/loading/loader-interceptor.service';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgxPhotoEditorModule} from 'ngx-photo-editor';
import {AppInitializerProviders} from './shared/utils/providers/app-initializer.provider';
import {registerLocaleData} from '@angular/common';
import localeTh from '@angular/common/locales/th';
import {FormsModule} from '@angular/forms';
import {HttpErrorInterceptor} from './shared/services/httperrorinterceptor.service';
import {PdfJsViewerModule} from 'ng2-pdfjs-viewer';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
registerLocaleData(localeTh);
// /*
@Injectable()
export class ParameterHashLocationStrategy extends HashLocationStrategy {
    prepareExternalUrl(internal: string): string {
        return window.location.search + super.prepareExternalUrl(internal);
    }
}
// */
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [AppComponent, LoaderComponent],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        AppRoutingModule,
        NgxSpinnerModule,
        NgbModule,
        NgbTimepickerModule,
        HttpClientModule,
        NgSelectModule,
        NgxPhotoEditorModule,
        FormsModule,
        PdfJsViewerModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        // /*
        {
            provide: LocationStrategy,
            useClass: ParameterHashLocationStrategy
        }, //*/
        LoaderService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptorService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        {provide: LOCALE_ID, useValue: 'en-US'},
        AppInitializerProviders
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
