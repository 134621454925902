export interface AlertType {
    cancel: string;
    delete: string;
    warning: string;
    danger: string;
    success: string;
    alert: string;
    info: string;
    error: string;
    copy: string;
    content: string;
    meet: string;
}

export const alertType: AlertType = {
    warning: 'warning',
    danger: 'danger',
    success: 'success',
    alert: 'alert',
    info: 'info',
    error: 'error',
    delete: 'delete',
    cancel: 'cancel',
    copy: 'copy',
    content: 'content',
    meet: 'meet'
};

export interface AlertConfig {
    title?: any;
    body?: any;
    confirmButton?: boolean;
    confirmButtonText?: string;
    cancelButton?: boolean;
    cancelButtonText?: string;
    canDismiss?: boolean;
    showIcon?: boolean;
}

export class alertConfig implements AlertConfig {
    title?: any = '';
    body?: any;
    confirmButton?: boolean;
    confirmButtonText?: string = 'ตกลง';
    cancelButton?: boolean;
    cancelButtonText?: string = 'ยกเลิก';
    canDismiss?: boolean;
    showIcon?: boolean;
}
