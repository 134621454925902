import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {CoreFetch} from './core/core';
import {Endpoint} from 'src/app/shared/utils/constants';
import {map as rxjsMap} from 'rxjs/operators';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CenterFetchService extends CoreFetch {
    constructor() {
        super();
    }

    public getIPAddress(): Observable<any> {
        if (!environment.production) {
            return of('0.0.0.0');
        }
        return this.getExternal<any>(environment.API_GETIP, null).pipe(
            rxjsMap(res => {
                return res.ip;
            })
        );
    }
}
