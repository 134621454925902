import {Injectable} from '@angular/core';
import {PermissionInfo, UserInfo} from '../model/permission';
import {ApiService} from './api.service';
import {HttpService} from './http.service';

@Injectable({
    providedIn: 'root'
})
export class PermissionService {
    private userInfo: UserInfo;
    private permissionInfo: PermissionInfo;
    private permissionList: Array<PermissionInfo> = [];
    constructor(private apiService: ApiService, public http: HttpService) {}
    private _userPermisssion: Map<string, number> = new Map();

    get PermissionList(): Array<PermissionInfo> {
        return this.permissionList;
    }

    getPermission(menuId: number, subMenuId: number) {
        var list = this.permissionList.length == 0 ? this.apiService.getMenu() : this.permissionList;
        // console.log('permission', this.apiService.getMenu(), this.permissionList);
        return list.filter(f => f.SubMenuId == subMenuId && f.MenuId === menuId);
    }
    getMenuSarabun(menuId: number, subMenuId: number) {
        var list = this.permissionList.length == 0 ? this.apiService.getMenuSarabun2() : this.permissionList;
        // console.log('permission', this.apiService.getMenuSarabun2(), this.permissionList);
        return list.filter(f => f.SubMenuId == subMenuId && f.MenuId === menuId);
    }


    async getUserInfoBookACar(): Promise<any> {
        // let EmployeeId = emID ? emID : this.apiService.getEmployeeId();
        // let ProgramId = pgID ? pgID : this.apiService.getProgramId();
        let EmployeeId = this.apiService.getEmployeeId();
        let ProgramId = this.apiService.getProgramId();
        let params = {EmployeeId: EmployeeId, ProgramId: ProgramId};
        let res = await this.http.get(`drt-BookACar/BC-GetUserInfo`, {...params}).toPromise();
        this.apiService.setUserInfo(res.data[0]);
        this.apiService.setMenu(res.Menu);

        this.permissionList = res.Menu;
    }

    async getUserInfoPersonnelSystem(): Promise<any> {
        // let EmployeeId = emID ? emID : this.apiService.getEmployeeId();
        // let ProgramId = pgID ? pgID : this.apiService.getProgramId();
        let EmployeeId = this.apiService.getEmployeeId();
        let ProgramId = this.apiService.getProgramId();
        let params = {EmployeeId: EmployeeId, ProgramId: ProgramId};
        let res = await this.http.get(`drt-PersonnelSystem/PS-GetUserInfo`, {...params}).toPromise();
        this.apiService.setUserInfo(res.data[0]);
        this.apiService.setMenu(res.Menu);

        this.permissionList = res.Menu;
    }
}
