import {Injectable, TemplateRef} from '@angular/core';
import Swal from 'sweetalert2';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {AlertComponent} from '../component/alert/alert.component';
import {AlertConfig, alertConfig, alertType, AlertType} from '../component/alert/alert-model';

@Injectable({
    providedIn: 'root'
})
export class AlertService {
    constructor(private modalService: NgbModal) {}

    modalReference: NgbModalRef;
    public AlertSwal(icon: any, title: any, text = '', footer = '') {
        return Swal.fire({
            icon,
            title,
            text,
            footer: footer ? footer : undefined
        });
    }

    public Confirm(title: any, textBtn = '', f: (res) => void, cancelButtonText = 'ยกเลิก') {
        return Swal.fire({
            title,
            showCancelButton: true,
            confirmButtonText: textBtn,
            cancelButtonText,
            confirmButtonColor: '#a00b0b'
        }).then(result => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                f(true);
            } else {
                f(false);
            }
        });
    }

    public ConfirmDelete(title: any, textBtn = '', f: () => void) {
        return Swal.fire({
            title,
            showCancelButton: true,
            confirmButtonText: textBtn,
            cancelButtonText: 'ยกเลิก',
            confirmButtonColor: '#a00b0b'
        }).then(result => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                f();
            }
        });
    }

    public Delete(
        title: any = 'ลบข้อมูล',
        text = 'ท่านต้องการลบข้อมูลหรือไม่',
        timeOut = null,
        confirmButton: any = 'ยืนยัน',
        cancelButton: any = 'ยกเลิก',
        canDismiss: any = true
    ) {
        return this.PrepareAlert(alertType.delete, title, text, timeOut, confirmButton, cancelButton, canDismiss);
    }

    public Copy(
        title: any = 'คัดลอกข้อมูล',
        text = 'ท่านต้องการคัดลอกข้อมูลหรือไม่',
        timeOut = null,
        confirmButton: any = 'ยืนยัน',
        cancelButton: any = 'ยกเลิก',
        canDismiss: any = true
    ) {
        return this.PrepareAlert(alertType.copy, title, text, timeOut, confirmButton, cancelButton, canDismiss);
    }

    public Cancel(
        title: any = 'ยกเลิก',
        text = 'ท่านต้องการยกเลิกหรือไม่',
        timeOut = null,
        confirmButton: any = 'ยืนยัน',
        cancelButton: any = 'ยกเลิก',
        canDismiss: any = true
    ) {
        return this.PrepareAlert(alertType.cancel, title, text, timeOut, confirmButton, cancelButton, canDismiss);
    }

    public ErrorNormal() {
        return this.Error('ผิดพลาด', 'กรุณาติดต่อผู้ดูแลระบบ');
    }

    public Success(
        title: any,
        text: any = '',
        timeOut = null,
        confirmButton: any = false,
        cancelButton: any = false,
        canDismiss: any = true
    ) {
        return this.PrepareAlert('success', title, text, timeOut, confirmButton, cancelButton, canDismiss);
    }

    public Error(title: any, text = '', timeOut = 3, confirmButton: any = false, cancelButton: any = false, canDismiss: any = true) {
        return this.PrepareAlert('error', title, text, timeOut, confirmButton, cancelButton, canDismiss);
    }

    public Warn(title: any, text = '', timeOut = 3, confirmButton: any = false, cancelButton: any = false, canDismiss: any = true) {
        return this.PrepareAlert('warning', title, text, timeOut, confirmButton, cancelButton, canDismiss);
    }

    public Danger(title: any, text = '', timeOut = 3, confirmButton: any = false, cancelButton: any = false, canDismiss: any = true) {
        return this.PrepareAlert('danger', title, text, timeOut, confirmButton, cancelButton, canDismiss);
    }

    public Info(title: any, text = '', timeOut = 3, confirmButton: any = false, cancelButton: any = false, canDismiss: any = true) {
        return this.PrepareAlert('info', title, text, timeOut, confirmButton, cancelButton, canDismiss);
    }

    public Alert(title: any, text = '', timeOut = 3, confirmButton: any = false, cancelButton: any = false, canDismiss: any = true) {
        return this.PrepareAlert('alert', title, text, timeOut, confirmButton, cancelButton, canDismiss);
    }

    public Content(title: any, body = {}, timeOut = null, confirmButton: any = false, cancelButton: any = false, canDismiss: any = true) {
        return this.PrepareAlertContent('content', title, body, timeOut, confirmButton, cancelButton, canDismiss);
    }
    
    public ContentMeeting(title: any, body = {}, timeOut = null, confirmButton: any = false, cancelButton: any = false, canDismiss: any = true) {
        return this.PrepareAlertContent('meet', title, body, timeOut, confirmButton, cancelButton, canDismiss);
    }

    PrepareAlert(
        type: string = alertType.alert,
        title = '',
        body = '',
        timeOut = null,
        confirmButton: any = false,
        cancelButton: any = false,
        canDismiss: any = true
    ): Promise<any> {
        const config = new alertConfig();
        config.showIcon = true;
        config.title = title;
        config.body = body;
        config.canDismiss = canDismiss;
        if (confirmButton) {
            config.confirmButton = true;
            if (confirmButton !== true) {
                config.confirmButtonText = confirmButton;
            }
        }

        if (cancelButton) {
            config.cancelButton = true;
            if (cancelButton !== true) {
                config.cancelButtonText = cancelButton;
            }
        }
        return this.ShowAlert(config, type, timeOut);
    }

    PrepareAlertContent(
        type: string = alertType.alert,
        title = '',
        body = {},
        timeOut = null,
        confirmButton: any = false,
        cancelButton: any = false,
        canDismiss: any = true
    ): Promise<any> {
        const config = new alertConfig();
        config.showIcon = true;
        config.title = title;
        config.body = body;
        config.canDismiss = canDismiss;
        if (confirmButton) {
            config.confirmButton = true;
            if (confirmButton !== true) {
                config.confirmButtonText = confirmButton;
            }
        }

        if (cancelButton) {
            config.cancelButton = true;
            if (cancelButton !== true) {
                config.cancelButtonText = cancelButton;
            }
        }
        return this.ShowAlert(config, type, timeOut);
    }

    ShowAlert(config: AlertConfig, type: string = alertType.alert, timeOut = null): Promise<alertConfig> {
        const params = {
            windowClass: 'alert-modal alert-modal' + type,
            size: 'md',
            centered: true,
            scrollable: true,
            //backdrop: 'static',
            keyboard: true
        } as any;
        const modalRef: NgbModalRef = this.modalService.open(AlertComponent, params);
        modalRef.componentInstance.alertType = type;
        modalRef.componentInstance.timeOut = timeOut;
        //// console.log('config', config);
        modalRef.componentInstance.config = config;
        this.modalReference = modalRef;
        return modalRef.closed.toPromise();
    }

    openModal(modal: TemplateRef<any> | any, size: string = 'sm', isStatic: boolean = false, scrollable: boolean = true): Promise<any> {
        const params = {
            windowClass: 'alert-modal',
            size: size,
            centered: true,
            scrollable: scrollable
        } as any;
        if (isStatic) {
            params.backdrop = 'static';
            params.keyboard = false;
        }
        const modalRef = this.modalService.open(modal, params);
        this.modalReference = modalRef;
        return modalRef.closed.toPromise();
    }

    closeModal() {
        this.modalReference.close();
    }
}
