import {Component, Directive, ElementRef, HostListener, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {NavigationEnd, Router} from '@angular/router';
import {AuthService} from './shared/services/auth.service';
import {NotificationService} from './shared/services/notification.service';
import {filter} from 'rxjs/operators';
import {LayoutService} from './shared/services/layout.service';
import {PermissionService} from './shared/services/permission.service';
import {ApiService} from './shared/services/api.service';
import {CenterFetchService} from './shared/utils/fetches/center.fetch';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'THE ONE Insurance Admin';

    @HostListener('click', ['$event.target'])
    onClick(btn) {
        let value = btn.getAttribute('role');
        if (value == 'add' || value == 'edit') {
            const invalidControl = this.el.nativeElement.querySelector('form');

            if (invalidControl) {
                invalidControl.classList.add('submitted');
            }
        }
    }

    constructor(
        private router: Router,
        public auth: AuthService,
        public Notification: NotificationService,
        private el: ElementRef,
        private layout: LayoutService,
        private centerFetchService: CenterFetchService
    ) {
        this.centerFetchService.getIPAddress().subscribe(res => {
            sessionStorage.setItem('DRTIPAddress', res);
        });
    }

    ngOnInit(): void {
        this.router.events.subscribe(evt => {
            if (evt instanceof NavigationEnd) {
                this.layout.NavigationEnd$.next(evt);
                window.scrollTo(0, 0);
                // const element = this.el.nativeElement.querySelector('[role="add"]');
            }
        });
    }
}
