import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {LoggerService} from '../services/logger.service';
import {LoaderInterceptorService} from './loader-interceptor.service';

// const logger = new LoggerService('Loader Service');

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    isInterceptorLoading = new BehaviorSubject(false);
    isGlobalLoading = new BehaviorSubject(false);
    activeRequests = 0;

    constructor() {}

    addRequests() {
        this.activeRequests++;
        // logger.debug('addRequests', this.activeRequests);
        this.checkLoading();
    }

    removeRequests() {
        this.activeRequests--;
        // logger.debug('removeRequests', this.activeRequests);
        this.checkLoading();
    } // */

    private checkLoading() {
        if (this.activeRequests > 0) {
            this.startLoading();
        } else {
            this.activeRequests = 0;
            this.stopLoading();
        }
    }

    private startLoading() {
        // logger.debug('startLoading', this.activeRequests);
        this.isGlobalLoading.next(true);
    }

    private stopLoading() {
        // logger.debug('stopLoading', this.activeRequests);
        this.isGlobalLoading.next(false);
    }

    resetLoading() {
        this.activeRequests = 0;
        this.isInterceptorLoading.next(false);
        this.isGlobalLoading.next(false);
    }
}
