import {HttpClient, HttpRequest, HttpEventType, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {isEmpty, isNil} from 'lodash';
import {EMPTY, Observable, of, of as rxjsOf, throwError} from 'rxjs';
import {catchError, switchMap, map} from 'rxjs/operators';
import {AlertService} from 'src/app/shared/services/alert.service';
import {ServiceLocator} from 'src/app/shared/services/service-locator.service';

export type CacheOptions = 'cache-first' | 'cache-only' | 'force-fetch';

export abstract class CoreFetch {
    private _http: HttpClient = ServiceLocator.mainInjector.get(HttpClient);
    constructor() {}

    protected get<T>(url: string, query: any = null): Observable<T> {
        const apiObj = this._http.get<T>(url + this.convertObjectToQueryString(query)).pipe(
            map((res: any) => {
                if (res.successful) {
                    return res;
                } else {
                    this.handlerCommonError(res.code + ' : ' + res.message);
                }
                // return res || {}
            }),
            catchError(this.handlerError)
        );
        return this.handleIsLoading(apiObj);
    }

    protected post<T>(url: string, body: any = null, contentType = 'application/json'): Observable<T> {
        // Http Options
        let httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': contentType
            })
        };

        if (contentType == 'multipart/form-data') {
            body = this.convertObjectToFormData(body);
            httpOptions = {
                headers: new HttpHeaders({})
            };
        }
        const apiObj = this._http.post<T>(url, body, httpOptions).pipe(
            map((res: any) => {
                // console.log(res);
                if (res.successful) {
                    return res;
                } else {
                    this.handlerCommonError(res.code + ' : ' + res.message);
                }
                // return res || {}
            }),
            catchError(this.handlerError)
        );
        return this.handleIsLoading(apiObj);
    }

    protected put<T>(url: string, body: any = null): Observable<T> {
        const apiObj = this._http.put<T>(url, body);
        return this.handleIsLoading(apiObj);
    }

    protected patch<T>(url: string, body: any = null): Observable<T> {
        const apiObj = this._http.patch<T>(url, body);
        return this.handleIsLoading(apiObj);
    }

    protected delete<T>(url: string, query: any = null): Observable<T> {
        const apiObj = this._http.delete<T>(url + this.convertObjectToQueryString(query));
        return this.handleIsLoading(apiObj);
    }

    protected getExternal<T>(url: string, query: any = null): Observable<T> {
        const apiObj = this._http.get<T>(url + this.convertObjectToQueryString(query)).pipe(
            map((res: any) => {
                return res;
            }),
            catchError(this.handlerError)
        );
        return this.handleIsLoading(apiObj);
    }
    //Error
    handlerError(error: HttpErrorResponse) {
        let errorMessage = '';
        let title = 'เกิดข้อผิดพลาดทางระบบ';
        if (error.error instanceof ErrorEvent) {
            // Handle client error
            errorMessage = `${error.error.message}`;
        } else {
            // console.log(error);
            // Handle server error
            errorMessage = 'http ' + `error code ${error.status} ${error.error.message}`;
        }

        const alert = ServiceLocator.mainInjector.get(AlertService);
        alert.Error(title, errorMessage || '');
        // console.log(errorMessage);
        return throwError(errorMessage);
    }

    //Commo Error
    handlerCommonError(error: any) {
        let errorMessage = '';
        let title = 'เกิดข้อผิดพลาดทางระบบ';
        errorMessage = error;
        const alert = ServiceLocator.mainInjector.get(AlertService);
        alert.Error(title, errorMessage || '');
        // console.log(errorMessage);
        return throwError(errorMessage);
    }
    private handleIsLoading<T>(apiObj: Observable<T>): Observable<T> {
        return new Observable<T>(ob => {
            const subscription = apiObj.subscribe(
                res => ob.next(res),
                err => ob.error(err),
                () => ob.complete()
            );

            return {
                unsubscribe() {
                    subscription.unsubscribe();
                }
            };
        });
    }

    protected convertObjectToQueryString(values: any): string {
        if (!values) {
            return '';
        }

        const query = Object.keys(values)
            .map(key => key + '=' + values[key])
            .join('&');
        return '?' + query;
    }

    protected convertObjectToFormData(values: any): FormData {
        if (!values) {
            return null;
        }

        const formData = new FormData();
        Object.keys(values).map(key => {
            // console.log(key, values[key]);
            formData.append(key, values[key]);
            // console.log('formData', formData);
        });

        let token = localStorage.getItem('DRTToken');
        formData.append('token', token);
        // formData = Object.keys(values)
        //     .map(key => key + '=' + values[key]);

        return formData;
    }

    protected getFinalRemote<T>(cache: T, fetchRemote: Observable<T>, cacheOptions: CacheOptions): Observable<T> {
        let finalRemote: Observable<T>;
        switch (cacheOptions) {
            case 'cache-first':
                finalRemote = !isEmpty(cache) ? of(cache) : fetchRemote;
                break;
            case 'cache-only':
                finalRemote = !isEmpty(cache) ? of(cache) : EMPTY;
                break;
            case 'force-fetch':
                finalRemote = fetchRemote;
                break;
        }
        return finalRemote;
    }
}
