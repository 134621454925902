import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {UserProfile} from '../model/model';
import {Router} from '@angular/router';

const USER_KEY = 'user';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    currentUser$ = new BehaviorSubject<UserProfile>({});

    constructor(public router: Router) {
        this.getUser();
        if (!this.isLoggedIn) {
            // this.router.navigate(['login']);
        }
    }

    logout() {
        localStorage.removeItem(USER_KEY);
        this.router.navigate(['/login']);
    }

    setUser(user: UserProfile) {
        localStorage.setItem(USER_KEY, JSON.stringify(user));
        this.currentUser$.next(user);
    }

    get user(): UserProfile {
        return this.getUser();
    }
    get userInfo() {
        return this.getUserInfo();
    }

    get isLoggedIn() {
        return +this.user?.StaffId > 0;
    }

    canCheck() {
        return [4, 5].includes(+this.user.PremissionId);
    }

    canApprove() {
        return [6].includes(+this.user.PremissionId);
    }

    canClaim() {
        return [2, 3].includes(+this.user.PremissionId);
    }

    isAdmin() {
        return [1].includes(+this.user.PremissionId);
    }

    getUserInfo() {
        let userInfo = localStorage.getItem('DRTUserInfo') ?? '';
        return userInfo ? JSON.parse(userInfo) : {};
    }

    getUser(): UserProfile {
        return {StaffId: 1};

        if (+this.currentUser$.value.StaffId > 0) {
            return this.currentUser$.value;
        }

        const userSession = localStorage.getItem(USER_KEY);
        if (userSession) {
            const user = JSON.parse(userSession);
            this.currentUser$.next(user);
            return user;
        }
    }
}
