import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ReviewComponent} from './e-sarabun/system/review/review.component';
const routes: Routes = [
    // /*
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
    },
    {
        path: 'home',
        redirectTo: '/home',
        pathMatch: 'full'
    }, // */
    {
        path: '',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
    },
    {
        path: 'e-meeting',
        loadChildren: () => import('./e-meeting/e-meeting.module').then(m => m.EMeetingModule)
    },
    {
        path: 'vehicle',
        loadChildren: () => import('./vehicle/vehicle.module').then(m => m.VehicleModule)
    },
    {
        path: 'doc',
        loadChildren: () => import('./e-doc/e-doc.module').then(m => m.EDocModule)
    },
    {
        path: 'hr',
        loadChildren: () => import('./e-hr/e-hr.module').then(m => m.EHrModule)
    },
    {
        path: 'sarabun',
        loadChildren: () => import('./e-sarabun/e-sarabun.module').then(m => m.ESarabunModule)
    },
    {
        path: 'assets',
        loadChildren: () => import('./assets/assets.module').then(m => m.AssetsModule)
    },
    {
        path: 'my-task',
        loadChildren: () => import('./my-task/my-task.module').then(m => m.MyTaskModule)
    },
    {
        path: 'review',
        component: ReviewComponent
    },
    {
        path: 'meeting',
        loadChildren: () => import('./meeting/meeting/meeting.module').then(m => m.MeetingModule)
    },
    {
        path: 'contract',
        loadChildren: () => import('./contract/contract.module').then(m => m.ContractModule)
    },
    {
        path: 'regis-doc',
        loadChildren: () => import('./regis-doc/regis-doc.module').then(m => m.RegisDocModule)
    },
    {
        path: 'ko',
        loadChildren: () => import('./ko/ko-routing.module').then(m => m.KoModule)
    },
    {
        path: 'doctrainer',
        loadChildren: () => import('./doctrainer/doctrainer.module').then(m => m.DocTrainerModule)
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
