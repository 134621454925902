import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {alertConfig, AlertConfig, alertType} from './alert-model';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
    alertTypes = alertType;

    @Input() alertType: string = this.alertTypes.alert;
    @Input() timeOut: any;
    @Input() config: AlertConfig = new alertConfig();

    constructor(public activeModal: NgbActiveModal) {}

    ngOnInit(): void {
        this.modalTimeout();
    }

    modalTimeout() {
        if (!this.timeOut) return; 
        setTimeout(() => {
            this.activeModal.close('close');
        }, +(this.timeOut * 1000));
    }
}
